<template>
  <b-sidebar
    id="sidebar-right"
    ref="modal"
    header-class="headerModalToggle"
    right
    shadow
    no-close-on-esc
    width="fit-content"
  >
    <template #title>
      <strong style="color: #101828">{{ `${$t("LeadHistory")}` }}</strong>
    </template>
    <template v-slot:header-close>
      <button
        style="color: #98A2B3;;height: 60px;"
        class="fa fa-close"
        @click="onCancelToggle"
      />
    </template>
    <div class="history">
      <div style="padding: 20px">
        <div
          class="tt"
          style="border-top: 0px solid lightgray; padding-top: 16px"
        >
          <button
            v-if="getLoggedInUser.role == 'CEO' || getLoggedInUser.role == 'Admin' || getLoggedInUser.role == 'SalesPerson'"
            v-b-modal.add-event
            class="button"
            style="margin-bottom: 3%; width: fit-content; border-radius: 6px"
          >
            <img
              src="../assets/images/plus-circle.svg"
              alt="plus"
            >
            {{ $t("AddCharacteristics") }}
          </button>
          <table
            class="team_table"
          >
            <thead style="border-bottom: 1px solid #dcdfe6; color: #606266; text-align: left">
              <tr>
                <th>
                  {{ $t("favoriteColor") }}
                </th>
                <th>
                  {{ $t("favoriteDress") }}
                </th>
                <th>
                  {{ $t("generalDescription") }}
                </th>
                <th>
                  {{ $t("Edit") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ getCharacteristicsByClientId.favoriteColor }}</td>
                <td>{{ getCharacteristicsByClientId.favoriteDress }}</td>
                <td>{{ getCharacteristicsByClientId.generalDescription }}</td>
                <td
                  v-if="Object.keys(getCharacteristicsByClientId).length > 0"
                  v-b-modal.edit-characteristics
                >
                  <b-icon-pencil />
                </td>

              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div style="border-left: 1px solid rgb(208, 207, 207); padding: 20px">
        <div
          class="tt"
          style="border-top: 0px solid lightgray; padding-top: 0px"
        >
          <div
            v-if="getLoggedInUser.role == 'CEO' || getLoggedInUser.role == 'Admin' || getLoggedInUser.role == 'SalesPerson'"
            v-b-modal.add-event-leads
            style="display: flex; cursor:pointer; gap: 10px; align-items: center; justify-content: space-between; padding-top: 16px;"
          >
            <p style="font-weight: bold; margin-bottom: 0px;">
              {{ $t("Events") }}
            </p>
            <button
              v-b-modal.add-event-leads
              class="button"
              style="width: fit-content; border-radius: 6px"
            >
              <img
                src="../assets/images/plus-circle.svg"
                alt="plus"
              >
              {{ $t("AddNewEvent") }}
            </button>
          </div>
          <hr style="width: 100%; color: rgb(208, 207, 207)">
          <ul
            v-for="events in getEventsReporting"
            :key="events.eventId"
            v-b-tooltip.hover.bottom
            data-aos="fade-down"
            data-aos-duration="1500"
            :title="$t('ClickToView')"
            class="alternating-colors"
          >
            <li
              v-b-modal.notes-events
              @click="showAll(events)"
            >
              <div class="iconsForm">
                <b-icon-alarm />
              </div>
              <div>
                <strong>{{ events.type }}</strong>
                <p>{{ convertUTCDateToLoacalDate(events.created) }}</p>
                <p style="color:#5C5C5C">
                  {{ $t('RegisteredBy') }} - {{ getUserFirstName(events.salesUserId) }}
                </p>
              </div>
            </li>
          </ul>
        </div>
        <b-pagination
          v-if="getTotalItemsForEvents > 4"
          v-model="page"
          style="padding-left: 0px;margin-top: 50px !important;"
          :total-rows="getTotalItemsForEvents"
          :per-page="pageSize"
          first-number
          last-number
          align="fill"
          prev-class="prev-itemiii"
          next-class="next-itemiii"
          class="mt-1 mb-0"
        >
          <template #prev-text>
            <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
          </template>
          <template #next-text>
            <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
          </template>
        </b-pagination>
      </div>
      <AddEvent
        :client-id="clientId"
        @character="addCharacteristicss"
      />
      <AddEventOfLeads
        :client-id="clientId"
        :lead-client="leadClient"
        @events="addEvents"
      />
      <EditCharacteristics
        :characteristics="getCharacteristicsByClientId"
        @editCharacter="editCharacteristicss"
      />
      <ShowNotesEvents
        :notes-of-events="notesOfEvents"
      />
    </div>
  </b-sidebar>
</template>

<script>
import AddEvent from '@/components/clients/modals/AddEvent.vue';
import ShowNotesEvents from '@/components/clients/modals/ShowNotesEvents.vue';
import AddEventOfLeads from '@/components/clients/modals/AddEventOfLeads.vue';
import EditCharacteristics from '@/components/clients/modals/EditCharacteristics.vue';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { client } from '@/domainConfig';



export default {
  components: {
    AddEvent,
    AddEventOfLeads,
    EditCharacteristics,
    ShowNotesEvents,
  },
  props: ['clientId', 'leadClient'],
  data() {
    return {
      page: 1,
      pageSize: 4,
      clientName: client.clientName,
      notesOfEvents: null,
    }
  },
  computed: {
    ...mapGetters([
      'getCharacteristicsByClientId',
      'getEventsReporting',
      'getTotalPagesForEvents',
      'getTotalItemsForEvents',
      'getLoggedInUser',
      'getUsersByRole',
    ]),
    allUsers() {
      return this.getUsersByRole.map((itemi) => { return { userId: itemi.userID, fullName: itemi.fullName } })
    },
  },
  watch: {
    page(value) {
      this.eventsReporting({
        clientId: this.clientId,
        pageNumber: value,
        pageSize: this.pageSize,
        clientName: null,
      });
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions([
      'addCharacteristic',
      'characteristicsByClientId',
      'eventsReporting',
      'addNewEvent',
      'editCharacteristics',
    ]),
    getUserFirstName(salesUserId) {
      const user = this.allUsers.find((u) => { return u.userId === salesUserId });
      if (user) {
        const firstName = user.fullName;
        console.log('firstName', firstName);
        return firstName;
      }
      console.log('User not found');
      return null;
    },
    onCancelToggle() {
      this.$emit('onCancelToggle');
    },
    convertUTCDateToLoacalDate(date) {
      const local = moment.utc(date).local().format('DD-MMM-YYYY h:mm A')
      return local
    },
    showAll(value) {
      console.log('value', value)
      this.notesOfEvents = value
    },
    addCharacteristicss(obj) {
      console.log('value', obj)
      console.log('clientName', null)
      this.addCharacteristic({
        clientName: null,
        object: obj,
        successCallback: () => {
          this.characteristicsByClientId({
            clientName: null,
            clientId: this.clientId,
          })
        },
      })
    },
    addEvents(obj) {
      this.addNewEvent({
        clientName: null,
        object: obj,
        successCallback: () => {
          this.eventsReporting({
            clientId: this.clientId,
            pageNumber: this.page,
            pageSize: this.pageSize,
            clientName: null,
          });
        },
      })
    },
    editCharacteristicss(obj) {
      this.editCharacteristics({
        clientId: this.clientId,
        object: obj,
        successCallback: () => {
          this.characteristicsByClientId({
            clientName: null,
            clientId: this.clientId,
          })
        },
      })
    },
  },
};
</script>

<style lang="scss" scoped>

.mt-1 {
    border: none
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s ease-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.iconsForm{
  background: #f82047;
    border-radius: 50%;
    color: white;
    width: 40px;
    height: 40px;
    align-items: center;
    display: flex;
    justify-content: center;
}
.team_table td {
  padding: 16px 9px;
}

.history {
  display: flex;
}

ul {
  padding-left: 0px;
}

li {
  list-style: none;
  position: relative;
  margin-top: 24px;
  display: flex;
  gap: 20px;
  align-items: center;
}

li p {
  margin-bottom: 0px;
}

ul:not(:last-of-type) li:after {
  content: "";
  display: block;
  height: 79%;
  width: 3px;
  background-color: #FF274F;
  position: absolute;
  top: 84px;
  left: 18px;
  transform: translateY(-50%);
}
.custom-icon {
  position: absolute;
  top: 0;
  left: 0;
}


strong {
  color: #292929;
}

ul.alternating-colors {
  li:nth-child(odd):before {
    border-color: #f82047;
  }
  li:nth-child(even):before {
    border-color: #7452e3;
  }
}

strong {
  color: #292929;
}

ul.alternating-colors {
  li:nth-child(odd):before {
    border-color: #f82047;
  }
  li:nth-child(even):before {
    border-color: #9913be;
  }
}
</style>
