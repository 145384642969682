import { render, staticRenderFns } from "./AddEventOfLeads.vue?vue&type=template&id=dda98844&scoped=true"
import script from "./AddEventOfLeads.vue?vue&type=script&lang=js"
export * from "./AddEventOfLeads.vue?vue&type=script&lang=js"
import style0 from "./AddEventOfLeads.vue?vue&type=style&index=0&id=dda98844&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dda98844",
  null
  
)

export default component.exports