<template>
  <div>
    <b-modal
      id="notes-events"
      ref="modal"
      header-class="headerModal"
      no-close-on-backdrop
      centered
      :title="$t('Notes')"
      hide-footer
      @close="onCancel"
    >
      <div style="display: flex; justify-content: space-between;">
        <b-form-group
          id="input-group-4"
          :label="$t('Ocassion')"
          label-for="input-9"
        >

          <b-form-input
            v-model="notesOfEvents.ocassion"
            style="width: 224px;"
            disabled
          />

        </b-form-group>
        <b-form-group
          id="input-group-1"
          :label="$t('Notes')"
          label-for="input-1"
        >
          <span
            v-b-tooltip.hover
            :title="notesOfEvents.notes"
          >
            <b-form-input
              v-model="notesOfEvents.notes"
              style="width: 224px;"
              disabled
            />
          </span>
        </b-form-group>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  components: {
  },
  props: ['notesOfEvents'],
  data() {
    return {
      form: {
        generalDescription: null,
        favoriteDress: null,
        favoriteColor: null,
      },
    }
  },
  methods: {
    ...mapActions(['loadSaturdayWork']),
    onSubmit() {
      const objekti = {
        ...this.form,
        clientId: this.clientId,
      }
      this.$emit('character', objekti);
      this.$refs.modal.hide()
      this.onReset()
    },
    onCancel() {
      this.$refs.modal.hide();
      this.onReset()
    },
    onReset() {
      this.form.generalDescription = null
      this.form.favoriteColor = null
      this.form.favoriteDress = null
    },
  },
}
</script>

  <style lang="scss" scoped>
    form {
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    }
    .deactivate-modal__select {
      display: flex;
      flex-direction: column;
      select {
        margin-bottom: 10px;
        padding: 10px;
      }
      button {
        align-self: end;
      }
    }
    .flex-form {
      display: flex;
      flex-direction: column;
    }
    .my-class {
    background: black;
    font-weight: bold;
  }

  </style>
