<template>
  <div>
    <b-modal
      id="add-lead-modal"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('LeadOwner')"
      hide-footer
      class="custom-modal"
      @close="onCancel"
    >
      <b-form>
        <!-- <b-form-group
          id="input-select-1"
          :label="$t('Role')"
          label-for="select-1"
        >
          <vue-select
            v-model="form.roles"
            :options="allRoles"
            :placeholder="$t('SelectRole')"
            label="label"
            aria-describedby="input-1-live-feedback"
            required
          />
        </b-form-group> -->
        <b-form-group
          id="input-select-1"
          :label="$t('Employee')"
          label-for="select-1"
        >
          <vue-select
            v-model="form.rolesEmployee"
            :options="employeesByRole"
            :reduce="(e) => e.userID"
            :placeholder="$t('SelectEmployee')"
            label="fullName"
            :state="validateState('rolesEmployee')"
            aria-describedby="input-1-live-feedback"
            required
          />
          <p
            v-if="$v.$anyError"
            style="margin-top:5px;margin-bottom: 0px;font-weight: bold;color:red;"
          >
            {{ $t('Please select an employee.') }}
          </p>
        </b-form-group>
        <b-form-group />
        <div class="buttonsEverywhere">
          <button
            type="button"
            variant="primary"
            class="buttonSubmit"
            style="margin-top: 10px;"

            @click="onSubmit"
          >
            {{ $t('Submit') }}
          </button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px; margin-top: 10px; background: white; color: black; border-color: white;font-weight: 400;"

            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate';
import {
  required,
}
from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],

  data() {
    return {
      form: {
        roles: '',
        rolesEmployee: '',
      },

    }
  },
  validations: {
    form: {
      rolesEmployee: {
        required,
      },
    },

  },
  computed: {
    ...mapGetters(['getTeamsNames', 'getUsersByRole', 'getUserRole']),
    allRoles() {
      return this.getUserRole
    },
    employeesByRole() {
      return this.getUsersByRole
    },
  },
  watch: {
    'form.roles': function (value) {
      console.log('value', value)
      if (value !== '') {
        this.usersByRole({
          personRole: value,
        });
      }
    },
  },
  mounted() {
    this.teamNames({
      pageNumber: 1,

      pageSize: 15,

    });
  },
  methods: {
    ...mapActions(['teamNames', 'usersByRole', 'getUsersRole']),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.$emit('leadi', this.form.rolesEmployee)
      this.$refs.modal.hide()
      this.resetForm()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
    },
    onCancel() {
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.resetForm()
    },
    resetForm() {
      this.form.roles = ''
      this.form.rolesEmployee = ''
    },
  },
}
</script>

<style lang="scss" scoped>

  // form {
  //   display: grid;
  //   grid-template-columns: 1fr 1fr;
  //   gap: 10px;
  // }
  // .flex-form {
  //   display: flex;
  //   flex-direction: column;
  // }
</style>
