<template>
  <b-sidebar
    id="clients-edit-modal"
    ref="modal"
    header-class="headerModalToggle"
    :title="$t('EditCliPr')"
    right
    shadow
    no-close-on-esc
    width="60%"
  >
    <template #title>
      <strong style="color: #101828;">{{ `${$t('EditCliPr')}` }}</strong>
    </template>
    <template
      v-slot:header-close
    >
      <button
        style="color: #98A2B3;padding-left: 0px; padding-right: 0px;height: 60px; "
        class="fa fa-close"
        @click="onCancelToggle"
      />
    </template>
    <div class="patient-profile-container">
      <div class="buttons-container" />
      <div class="edit-container">
        <div class="first-container">
          <div class="header">
            <!-- <h1> {{ $t('EditCliPr') }}</h1> -->
          </div>
          <b-form>
            <b-form-group :label="`${$t('Salutation')}:`">
              <vue-select
                v-model="$v.personal.salutation.$model"
                :options="salutation"
                :placeholder="$t('SelectSalutation')"
                aria-describedby="input-1-live-feedback"
              />

            </b-form-group>
            <b-form-group
              id="input-group-1"
              :label="$t('FirstName')"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="$v.personal.firstName.$model"
                placeholder="e.g. Michael"
                :state="validateState('firstName')"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-2"
              :label="$t('LastName')"
              label-for="input-2"
            >
              <b-form-input
                id="input-2"
                v-model="$v.personal.lastName.$model"
                :state="validateState('lastName')"
                placeholder="e.g. Goodman"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t('This_is_a_required_field_and_must_be_at_least_3_letters')
              }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group :label="`${$t('Gender')}:`">
              <vue-select
                v-model="$v.personal.gender.$model"
                :options="genderOptions"
                :reduce="(e) => e.value"
                label="text"
                :placeholder="$t('SelectGender')"
                aria-describedby="input-1-live-feedback"
              />

            </b-form-group>
            <b-form-group
              id="input-group-4"
              :label="$t('Email')"
              label-for="input-4"
            >
              <b-form-input
                id="input-4"
                v-model="$v.personal.email.$model"
                placeholder="e.g. example@digitbusiness.ch"
                aria-describedby="input-1-live-feedback"
              />

            </b-form-group>

            <b-form-group
              id="input-group-7"
              :label="$t('PhoneN')"
              label-for="input-7"
            >
              <vue-phone-number-input
                v-model="personal.telephoneNumber"
                :default-country-code="personal.countryCode"

                :preferred-countries="['AL', 'CH', 'DE']"
                :no-example="true"
                @update="getFormattedNumber"
              />
            </b-form-group>
            <b-form-group
              id="input-group-9"
              :label="`${$t('DateofBirth')}:`"
              label-for="input-9"
            >
              <date-picker
                v-model="personal.dateOfBirth"
                value-type="format"
                class="form-control"
                format="DD-MM-YYYY"
                type="date"
                :placeholder="$t('DateofBirth')"
                style="width: 100%;"
                :lang="lang"
              />
            </b-form-group>
          </b-form>
        </div>
      </div>
      <div class="patient-history-container">
        <div class="first-container">
          <div class="header">
            <h1>{{ $t('BillingAddress') }}</h1>
          </div>
          <b-form>
            <b-form-group
              id="input-group-1"
              :label="$t('Street')"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="billingAddressInternalDto.street"
                placeholder="e.g. Rexhep Maja"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t('This_is_a_required_field_and_must_be_at_least_3_letters')
              }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-2"
              :label="$t('HouseNumber')"
              label-for="input-2"
            >
              <b-form-input
                id="input-2"
                v-model="billingAddressInternalDto.houseNumber"
                placeholder="e.g. 30"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback id="input-1-live-feedback"> {{
                $t('This_is_a_required_field_and_must_contains_numbers')
              }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-4"
              :label="$t('DoorNumber')"
              label-for="input-4"
            >
              <b-form-input
                id="input-4"
                v-model="billingAddressInternalDto.doorNumber"
                type="text"
                placeholder="e.g. 50"
                step="any"
                min="0"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t('This_is_a_required_field_and_must_contains_numbers')
              }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              id="input-group-5"
              :label="$t('PostalCode')"
              label-for="input-5"
            >
              <b-form-input
                id="input-5"
                v-model="billingAddressInternalDto.postalCode"
                min="0"
                placeholder="e.g. 10000"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t('This_is_a_required_field_and_must_be_at_least_3_letters')
              }}</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              id="input-group-7"
              :label="$t('City')"
              label-for="input-7"
            >
              <b-form-input
                id="input-7"
                v-model="billingAddressInternalDto.city"
                placeholder="e.g. Prishtinë"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t('This_is_a_required_field_and_must_be_at_least_3_letters')
              }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-7"
              :label="$t('Country')"
              label-for="input-7"
            >
              <b-form-input
                id="input-7"
                v-model="billingAddressInternalDto.country"
                placeholder="e.g. Kosovë"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t('This_is_a_required_field_and_must_be_at_least_3_letters')
              }}</b-form-invalid-feedback>
            </b-form-group>
          </b-form>
        </div>
        <div class="second-container">
          <div class="header">
            <h1>{{ $t('ShippingAddress') }}</h1>
          </div>
          <b-form>
            <b-form-group
              id="input-group-1"
              :label="$t('Street')"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="shippingAddressInternalDto.street"
                placeholder="e.g. Rexhep Maja"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback>{{
                $t('This_is_a_required_field_and_must_be_at_least_3_letters')
              }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-2"
              :label="$t('HouseNumber')"
              label-for="input-2"
            >
              <b-form-input
                id="input-2"
                v-model="shippingAddressInternalDto.houseNumber"
                placeholder="e.g. 30"
                type="text"
                step="any"
                min="0"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t('This_is_a_required_field_and_must_contains_numbers')
              }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-4"
              :label="$t('DoorNumber')"
              label-for="input-4"
            >
              <b-form-input
                id="input-4"
                v-model="shippingAddressInternalDto.doorNumber"
                type="text"
                placeholder="e.g. 50"
                step="any"
                min="0"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t('This_is_a_required_field_and_must_contains_numbers')
              }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              id="input-group-5"
              :label="$t('PostalCode')"
              label-for="input-5"
            >
              <b-form-input
                id="input-5"
                v-model="shippingAddressInternalDto.postalCode"
                placeholder="e.g. 10000"
                min="0"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t('This_is_a_required_field_and_must_be_at_least_3_letters')
              }}</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              id="input-group-7"
              :label="$t('City')"
              label-for="input-7"
            >
              <b-form-input
                id="input-7"
                v-model="shippingAddressInternalDto.city"
                placeholder="e.g. Prishtinë"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t('This_is_a_required_field_and_must_be_at_least_3_letters')
              }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-7"
              :label="$t('Country')"
              label-for="input-7"
            >
              <b-form-input
                id="input-7"
                v-model="shippingAddressInternalDto.country"
                placeholder="e.g. Kosovë"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t('This_is_a_required_field_and_must_be_at_least_3_letters')
              }}</b-form-invalid-feedback>
            </b-form-group>
          </b-form>
          <div style="display: flex; flex-direction: column">
            <span
              class="duplicate-material"
              @click="duplicateBillingAddress"
            >
              {{ $t("SameAsBillingAddress") }}
            </span>
          <!-- <button
            type="button"
            class="save-btn"
            :class="{ 'transparent': isPhoneNumberValid === false || isPhoneNumberValid === null }"
            :disabled="isPhoneNumberValid === false || isPhoneNumberValid === null"
            @click=" updateClientInfo()"
          >
            {{ $t("SaveAll") }}
          </button> -->
          </div>
        </div>
      </div>
      <div
        v-if="client == 'ValdrinSahiti'"
        class="skica-container"
      >
        <b-collapse
          visible
        >
          <div
            class="front-body"
          >

            <div class="betweenCol">
              <div class="input">
                <span>{{ $t('toraks') }}</span>
                <b-form-input
                  v-model="bodyMeasurementDto.thorax"
                  :placeholder="$t('')"
                  class="toraks1"
                />
              </div>
              <div class="input">
                <span>{{ $t('Bust') }}</span>
                <b-form-input
                  v-model="bodyMeasurementDto.aroundBust"
                  :placeholder="$t('')"
                  class="rrethiGjoksit1"
                />
              </div>
              <div class="input">
                <span>{{ $t('UnderBust') }}</span>
                <b-form-input
                  v-model="bodyMeasurementDto.aroundUnderBust"
                  :placeholder="$t('')"
                  class="rrethiNenGjoksit1"
                />
              </div>
              <div class="input">
                <span>{{ $t('Abdomen') }}</span>
                <b-form-input
                  v-model="bodyMeasurementDto.waist"
                  :placeholder="$t('')"
                  class="beli1"
                />
              </div>
              <div class="input">
                <span>{{ $t('Hips') }}</span>
                <b-form-input
                  v-model="bodyMeasurementDto.aroundHips"
                  :placeholder="$t('')"
                  class="vithet1"
                />
              </div>
              <div class="input">
                <span>{{ $t('BustReduction') }}</span>
                <b-form-input
                  v-model="bodyMeasurementDto.aroundLeg"
                  :placeholder="$t('')"
                  class="rrethiKembes1"
                />
              </div>
              <div class="input">
                <span>{{ $t('UnderBustReduction') }}</span>
                <b-form-input
                  v-model="bodyMeasurementDto.aroundKnee"
                  :placeholder="$t('')"
                  class="rrethiGjurit1"
                />
              </div>
              <div class="input">
                <span>{{ $t('FrontBody') }}</span>
                <b-form-input
                  v-model="bodyMeasurementDto.ankle"
                  :placeholder="$t('')"
                  class="zoguKembeve1"
                />
              </div>


            </div>
            <div class="betweenCol">


              <div class="input">
                <span>{{ $t('BackBody') }}</span>
                <b-form-input
                  v-model="bodyMeasurementDto.breastReduction"
                  :placeholder="$t('')"
                  class="reniaGjoksit1"
                />
              </div>
              <div class="input">
                <span>{{ $t('Shoulders') }}</span>
                <b-form-input
                  v-model="bodyMeasurementDto.chestDistance"
                  :placeholder="$t('')"
                  class="distancaGjoksit1"
                />
              </div>
              <div class="input">
                <span>{{ $t('ShouldersSeam') }}</span>
                <b-form-input
                  v-model="bodyMeasurementDto.sleeveLength"
                  :placeholder="$t('')"
                  class="distancaGjoksit1"
                />
              </div>
              <div class="input">
                <span>{{ $t('Distance') }}</span>
                <b-form-input
                  v-model="bodyMeasurementDto.interBust"
                  :placeholder="$t('')"
                  class="nderGjoks1"
                />
              </div>

              <div class="input">
                <span>{{ $t('ButtocksReduction') }}</span>
                <b-form-input
                  v-model="bodyMeasurementDto.frontBody"
                  :placeholder="$t('')"
                  class="trupiPara1"
                />
              </div>
              <div class="input">
                <span>{{ $t('Height') }}</span>
                <b-form-input
                  v-model="bodyMeasurementDto.length1"
                  :placeholder="$t('')"
                  class="gjatesia11"
                />
              </div>
              <div class="input">
                <span>{{ $t('3QuartersLength') }}</span>
                <b-form-input
                  v-model="bodyMeasurementDto.length2"
                  :placeholder="$t('')"
                  class="gjatesia21"
                />
              </div>
            </div>
            <div class="betweenCol">

              <div class="input">
                <span>{{ $t('ChanelLength') }}</span>
                <b-form-input
                  v-model="bodyMeasurementDto.length3"
                  :placeholder="$t('')"
                  class="gjatesia31"
                />
              </div>
              <div class="input">
                <span>{{ $t('MiniLength') }}</span>
                <b-form-input
                  v-model="bodyMeasurementDto.length4"
                  :placeholder="$t('')"
                  class="gjatesia41"
                />
              </div>
              <div class="input">
                <span>{{ $t('SleeveCircumference') }}</span>
                <b-form-input
                  v-model="bodyMeasurementDto.aroundSleeve"
                  :placeholder="$t('')"
                  class="rrethiMenges1"
                />
              </div>
              <div class="input">
                <span>{{ $t('SleeveLength') }}</span>
                <b-form-input
                  v-model="bodyMeasurementDto.elbow"
                  :placeholder="$t('')"
                  class="brryli1"
                />
              </div>
              <div class="input">
                <span>{{ $t('M.3QuartersLength') }}</span>
                <b-form-input
                  v-model="bodyMeasurementDto.wrist"
                  :placeholder="$t('')"
                  class="kyçi1"
                />
              </div>
              <div class="input">
                <span>{{ $t('ElbowCircumference') }}</span>
                <b-form-input
                  v-model="bodyMeasurementDto.aroundNeck"
                  :placeholder="$t('')"
                  class="rrethiQafes1"
                />
              </div>
              <div class="input">
                <span>{{ $t('WristCircumference') }}</span>
                <b-form-input
                  v-model="bodyMeasurementDto.backBody"
                  :placeholder="$t('')"
                  class="trupiPrapa1"
                />
              </div>
            </div>
          </div>

        </b-collapse>
      </div>
      <div
        v-else
        class="skica-container"
        style="padding-top: 15px;"
      >
        <b-collapse
          :id="`collapse`"
          visible
        >
          <div
            class="btn-con"
          >
            <button
              type="button"
              class="button"
              @click="showBody = !showBody"
            >
              {{ $t('FlipBody') }}
            </button>
          </div>
          <!-- v-if="dress.showFrontBody" -->
          <div
            v-if="showBody "
            class="front-body"
          >
            <img
              src="../../../assets/images/front-body.svg"
              alt=""
            >
            <div class="toraks">
              <b-form-input
                v-model="bodyMeasurementDto.thorax"
                :placeholder="$t('toraks')"
                class="toraks1"
              />
              <b-form-radio
                v-model="radioStates.thorax"
                :value="true"
                :unchecked-value="false"
                class="pink-radio"
                :disabled="bodyMeasurementDto.thorax === '' || bodyMeasurementDto.thorax === null"
              />
            </div>
            <div class="rrethiGjoksit">
              <b-form-input
                v-model="bodyMeasurementDto.aroundBust"
                :placeholder="$t('rrethiGjoksit')"
                class="rrethiGjoksit1"
              />
              <b-form-radio
                v-model="radioStates.aroundBust"
                :value="true"
                :unchecked-value="false"
                class="pink-radio"
                :disabled="bodyMeasurementDto.aroundBust === '' || bodyMeasurementDto.aroundBust === null"
              />
            </div>
            <div class="rrethiNenGjoksit">
              <b-form-input
                v-model="bodyMeasurementDto.aroundUnderBust"
                :placeholder="$t('rrethiNenGjoksit')"
                class="rrethiNenGjoksit1"
              />
              <b-form-radio
                v-model="radioStates.aroundUnderBust"
                :value="true"
                :unchecked-value="false"
                class="pink-radio"
                :disabled="bodyMeasurementDto.aroundUnderBust === '' || bodyMeasurementDto.aroundUnderBust === null"
              />
            </div>
            <div class="beli">
              <b-form-input
                v-model="bodyMeasurementDto.waist"
                :placeholder="$t('beli')"
                class="beli1"
              />
              <b-form-radio
                v-model="radioStates.waist"
                :value="true"
                :unchecked-value="false"
                class="pink-radio"
                :disabled="bodyMeasurementDto.waist === '' || bodyMeasurementDto.waist === null"
              />
            </div>
            <div class="vithet">
              <b-form-radio
                v-model="radioStates.aroundHips"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="bodyMeasurementDto.aroundHips === '' || bodyMeasurementDto.aroundHips === null"
              />
              <b-form-input
                v-model="bodyMeasurementDto.aroundHips"
                :placeholder="$t('RrethiVitheve')"
                class="vithet1"
              />
            </div>
            <div class="rrethiKembes">
              <b-form-radio
                v-model="radioStates.aroundLeg"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="bodyMeasurementDto.aroundLeg === '' || bodyMeasurementDto.aroundLeg === null"
              />
              <b-form-input
                v-model="bodyMeasurementDto.aroundLeg"
                :placeholder="$t('rrethiKembes')"
                class="rrethiKembes1"
              />
            </div>
            <div class="rrethiGjurit">
              <b-form-radio
                v-model="radioStates.aroundKnee"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="bodyMeasurementDto.aroundKnee === '' || bodyMeasurementDto.aroundKnee === null"
              />
              <b-form-input
                v-model="bodyMeasurementDto.aroundKnee"
                :placeholder="$t('rrethiGjurit')"
                class="rrethiGjurit1"
              />
            </div>
            <div class="zoguKembeve">
              <b-form-radio
                v-model="radioStates.ankle"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="bodyMeasurementDto.ankle === '' || bodyMeasurementDto.ankle === null"
              />
              <b-form-input
                v-model="bodyMeasurementDto.ankle"
                :placeholder="$t('zoguKembeve')"
                class="zoguKembeve1"
              />
            </div>

            <div class="reniaGjoksit">
              <b-form-radio
                v-model="radioStates.breastReduction"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="bodyMeasurementDto.breastReduction === '' || bodyMeasurementDto.breastReduction === null"
              />
              <b-form-input
                v-model="bodyMeasurementDto.breastReduction"
                :placeholder="$t('reniaGjoksit')"
                class="reniaGjoksit1"
              />
            </div>
            <div class="distancaGjoksit">
              <b-form-radio
                v-model="radioStates.chestDistance"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="bodyMeasurementDto.chestDistance === '' || bodyMeasurementDto.chestDistance === null"
              />
              <b-form-input
                v-model="bodyMeasurementDto.chestDistance"
                :placeholder="$t('distancaGjoksit')"
                class="distancaGjoksit1"
              />
            </div>
            <div class="nderGjoks">
              <b-form-radio
                v-model="radioStates.interBust"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="bodyMeasurementDto.interBust === '' || bodyMeasurementDto.interBust === null"
              />
              <b-form-input
                v-model="bodyMeasurementDto.interBust"
                :placeholder="$t('nderGjoks')"
                class="nderGjoks1"
              />
            </div>
            <div class="trupiPara">
              <b-form-radio
                v-model="radioStates.frontBody"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="bodyMeasurementDto.frontBody === '' || bodyMeasurementDto.frontBody === null"
              />
              <b-form-input
                v-model="bodyMeasurementDto.frontBody"
                :placeholder="$t('trupiPara')"
                class="trupiPara1"
              />
            </div>
            <div class="gjatesia1">
              <b-form-input
                v-model="bodyMeasurementDto.length1"
                :placeholder="$t('gjatesia1')"
                class="gjatesia11"
              />
              <b-form-radio
                v-model="radioStates.length1"
                :value="true"
                :unchecked-value="false"
                class="pink-radio"
                :disabled="bodyMeasurementDto.length1 === '' || bodyMeasurementDto.length1 === null"
              />
            </div>
            <div class="gjatesia2">
              <b-form-input
                v-model="bodyMeasurementDto.length2"
                :placeholder="$t('gjatesia2')"
                class="gjatesia21"
              />
              <b-form-radio
                v-model="radioStates.length2"
                :value="true"
                :unchecked-value="false"
                class="pink-radio"
                :disabled="bodyMeasurementDto.length2 === '' || bodyMeasurementDto.length2 === null"
              />
            </div>
            <div class="gjatesia3">
              <b-form-input
                v-model="bodyMeasurementDto.length3"
                :placeholder="$t('gjatesia3')"
                class="gjatesia31"
              />
              <b-form-radio
                v-model="radioStates.length3"
                :value="true"
                :unchecked-value="false"
                class="pink-radio"
                :disabled="bodyMeasurementDto.length3 === '' || bodyMeasurementDto.length3 === null"
              />
            </div>
            <div class="gjatesia4">
              <b-form-input
                v-model="bodyMeasurementDto.length4"
                :placeholder="$t('gjatesia4')"
                class="gjatesia41"
              />
              <b-form-radio
                v-model="radioStates.length4"
                :value="true"
                :unchecked-value="false"
                class="pink-radio"
                :disabled="bodyMeasurementDto.length4 === '' || bodyMeasurementDto.length4 === null"
              />
            </div>
          </div>
          <div
            v-if="!showBody"
            class="back-body"
          >
            <img
              src="../../../assets/images/back-body.svg"
              alt=""
            >
            <div class="rrethiMenges">
              <b-form-radio
                v-model="radioStates.aroundSleeve"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="bodyMeasurementDto.aroundSleeve === '' || bodyMeasurementDto.aroundSleeve === null"
              />
              <b-form-input
                v-model="bodyMeasurementDto.aroundSleeve"
                :placeholder="$t('rrethiMenges')"
                class="rrethiMenges1"
              />
            </div>
            <div class="brryli">
              <b-form-radio
                v-model="radioStates.elbow"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="bodyMeasurementDto.elbow === '' || bodyMeasurementDto.elbow === null"
              />
              <b-form-input
                v-model="bodyMeasurementDto.elbow"
                :placeholder="$t('brryli')"
                class="brryli1"
              />
            </div>
            <div class="kyçi">
              <b-form-input
                v-model="bodyMeasurementDto.wrist"
                :placeholder="$t('kyqi')"
                class="kyçi1"
              />
              <b-form-radio
                v-model="radioStates.wrist"
                :value="true"
                :unchecked-value="false"
                class="pink-radio"
                :disabled="bodyMeasurementDto.wrist === '' || bodyMeasurementDto.wrist === null"
              />
            </div>
            <div class="rrethiQafes">
              <b-form-radio
                v-model="radioStates.aroundNeck"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="bodyMeasurementDto.aroundNeck === '' || bodyMeasurementDto.aroundNeck === null"
              />
              <b-form-input
                v-model="bodyMeasurementDto.aroundNeck"
                :placeholder="$t('rrethiQafes')"
                class="rrethiQafes1"
              />
            </div>
            <div class="trupiPrapa">
              <b-form-radio
                v-model="radioStates.backBody"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="bodyMeasurementDto.backBody === '' || bodyMeasurementDto.backBody === null"
              />
              <b-form-input
                v-model="bodyMeasurementDto.backBody"
                :placeholder="$t('trupiPrapa')"
                class="trupiPrapa1"
              />
            </div>
            <div class="supet">
              <b-form-input
                v-model="bodyMeasurementDto.shoulders"
                :placeholder="$t('supet')"
                class="supet1"
              />
              <b-form-radio
                v-model="radioStates.shoulders"
                :value="true"
                :unchecked-value="false"
                class="pink-radio"
                :disabled="bodyMeasurementDto.shoulders === '' || bodyMeasurementDto.shoulders === null"
              />
            </div>
            <div class="shpina">
              <b-form-input
                v-model="bodyMeasurementDto.back"
                :placeholder="$t('shpina')"
                class="shpina1"
              />
              <b-form-radio
                v-model="radioStates.back"
                :value="true"
                :unchecked-value="false"
                class="pink-radio"
                :disabled="bodyMeasurementDto.back === '' || bodyMeasurementDto.back === null"
              />
            </div>
            <div class="reniaVitheve">
              <b-form-radio
                v-model="radioStates.declineOfTheHips"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="bodyMeasurementDto.declineOfTheHips === '' || bodyMeasurementDto.declineOfTheHips === null"
              />
              <b-form-input
                v-model="bodyMeasurementDto.declineOfTheHips"
                :placeholder="$t('reniaVitheve')"
                class="reniaVitheve1"
              />
            </div>
            <div class="gjatesiaMenges">
              <b-form-radio
                v-model="radioStates.sleeveLength"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="bodyMeasurementDto.sleeveLength === '' || bodyMeasurementDto.sleeveLength === null"
              />
              <b-form-input
                v-model="bodyMeasurementDto.sleeveLength"
                :placeholder="$t('gjatesiaMenges')"
                class="gjatesiaMenges1"
              />
            </div>
          </div>
        </b-collapse>
      </div>
      <div
        class="container"
        style="display: flex; justify-content: flex-end; margin-bottom: 35px;"
      >
        <button
          type="button"
          class="button"
          @click=" updateClientInfo()"
        >
          {{ $t("SaveAll") }}
        </button>
      </div>
    <!-- <CheckoutPatient /> -->
    </div>
  </b-sidebar>
</template>

<script>
// import CheckoutPatient from "./modals/CheckoutPatient.vue";
import DatePicker from 'vue2-datepicker';
import { validationMixin } from 'vuelidate';
import {
  required, minLength,
} from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
import { client } from '@/domainConfig'
import moment from 'moment';
import 'vue2-datepicker/index.css';



export default {
  components: {
    // CheckoutPatient,
    DatePicker,
  },
  mixins: [validationMixin],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    id: {
      type: String,
    },
  },
  data() {
    return {
      client: client.clientName,
      isPhoneNumberValid: null,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
      formSubmitted: false,
      salutation: ['Mr.', 'Ms.', 'BA.', 'BSc.', 'DI.', 'MA.', 'MSc.', 'MBA.', 'PhD.'],
      personal: {
        salutation: '',
        firstName: '',
        lastName: '',
        gender: '',
        email: '',
        telephoneNumber: '',
        countryCode: '',
        dateOfBirth: '',
      },
      billingAddressInternalDto: {
        street: '',
        houseNumber: '',
        doorNumber: '',
        postalCode: '',
        city: '',
        country: '',
      },
      shippingAddressInternalDto: {
        street: '',
        houseNumber: '',
        doorNumber: '',
        postalCode: '',
        city: '',
        country: '',
      },
      bodyMeasurementDto: {
        thorax: '',
        aroundBust: '',
        aroundUnderBust: '',
        waist: '',
        aroundHips: '',
        aroundLeg: '',
        aroundKnee: '',
        ankle: '',
        breastReduction: '',
        interBust: '',
        frontBody: '',
        chestDistance: '',
        length1: '',
        length2: '',
        length3: '',
        length4: '',
        aroundSleeve: '',
        elbow: '',
        wrist: '',
        aroundNeck: '',
        backBody: '',
        shoulders: '',
        back: '',
        declineOfTheHips: '',
        sleeveLength: '',
      },
      radioStates: {
        thorax: false,
        aroundBust: false,
        aroundUnderBust: false,
        waist: false,
        aroundHips: false,
        aroundLeg: false,
        aroundKnee: false,
        ankle: false,
        breastReduction: false,
        chestDistance: false,
        interBust: false,
        frontBody: false,
        length1: false,
        length2: false,
        length3: false,
        length4: false,
        aroundSleeve: false,
        elbow: false,
        wrist: false,
        aroundNeck: false,
        backBody: false,
        shoulders: false,
        back: false,
        declineOfTheHips: false,
        sleeveLength: false,
      },
      showBody: true,
      gender: null,
      genderOptions: [
        { value: 'Male', text: this.$t('Male') },
        { value: 'Female', text: this.$t('Female') },
        { value: 'Other', text: this.$t('Other') },
      ],
      bloodType: null,
      bloodOptions: [
        { value: null, text: 'Select blood group' },
        { value: 'A+', text: 'A+' },
        { value: 'A-', text: 'A-' },
        { value: 'B+', text: 'B+' },
        { value: 'B-', text: 'B-' },
        { value: 'AB+', text: 'AB+' },
        { value: 'AB-', text: 'AB-' },
        { value: '0+', text: '0+' },
        { value: '0-', text: '0-' },
      ],
      priority: null,
      priorityOptions: [
        { value: null, text: 'Select priority' },
        { value: 'Urgent', text: 'Urgent' },
        { value: 'Important', text: 'Important' },
        { value: 'Normal', text: 'Normal' },
        { value: 'Low', text: 'Low' },
      ],
      phoneNumber: '',
    };
  },
  validations: {
    personal: {
      salutation: {
      },
      firstName: {
        required,
        minLength: minLength(3),
      },
      lastName: {
        required,
        minLength: minLength(3),
      },
      email: {
        // required,
        // email,
      },
      gender: {
      },
    },
    // billingAddressInternalDto: {
    //   street: {
    //     required,
    //     minLength: minLength(3),
    //   },
    //   houseNumber: {
    //   },
    //   doorNumber: {
    //   },
    //   postalCode: {
    //     required,
    //     regex: /^[A-Za-z0-9\s]+$/,
    //     minLength: minLength(3),
    //   },
    //   city: {
    //     required,
    //   },
    //   country: {
    //     required,
    //   },
    // },
    // shippingAddressInternalDto: {
    //   street: {
    //     required,
    //     minLength: minLength(3),
    //   },
    //   houseNumber: {
    //   },
    //   doorNumber: {
    //   },
    //   postalCode: {
    //     required,
    //     regex: /^[A-Za-z0-9\s]+$/,
    //     minLength: minLength(3),
    //   },
    //   city: {
    //     required,
    //   },
    //   country: {
    //     required,
    //   },
    // },
  },

  computed: {
    ...mapGetters(['getClient']),
    clientById() {
      return this.$store.getters.getClient;
    },
  },
  watch: {
    clientById: {
      handler(newVal) {
        console.log('newVal', newVal)
        const formattedDateOfBirth = newVal.dateOfBirth
          ? moment(newVal.dateOfBirth).format('DD-MM-YYYY')
          : null;
        this.personal = {
          salutation: newVal.salutation,
          firstName: newVal.firstName,
          lastName: newVal.lastName,
          gender: newVal.gender,
          email: newVal.email,
          telephoneNumber: newVal.telephoneNumber,
          countryCode: newVal.countryCode,
          dateOfBirth: formattedDateOfBirth,
        }
        this.billingAddressInternalDto = {
          street: newVal.billingAddressDto.street,
          houseNumber: newVal.billingAddressDto.houseNumber,
          doorNumber: newVal.billingAddressDto.doorNumber,
          postalCode: newVal.billingAddressDto.postalCode,
          city: newVal.billingAddressDto.city,
          country: newVal.billingAddressDto.country,
        }
        this.shippingAddressInternalDto = {
          street: newVal.shippingAddressDto.street,
          houseNumber: newVal.shippingAddressDto.houseNumber,
          doorNumber: newVal.shippingAddressDto.doorNumber,
          postalCode: newVal.shippingAddressDto.postalCode,
          city: newVal.shippingAddressDto.city,
          country: newVal.shippingAddressDto.country,
        }
        this.bodyMeasurementDto = {
          thorax: newVal.bodyMeasurementDto.thorax,
          aroundBust: newVal.bodyMeasurementDto.aroundBust,
          aroundUnderBust: newVal.bodyMeasurementDto.aroundUnderBust,
          waist: newVal.bodyMeasurementDto.waist,
          aroundHips: newVal.bodyMeasurementDto.aroundHips,
          aroundLeg: newVal.bodyMeasurementDto.aroundLeg,
          aroundKnee: newVal.bodyMeasurementDto.aroundKnee,
          ankle: newVal.bodyMeasurementDto.ankle,
          breastReduction: newVal.bodyMeasurementDto.breastReduction,
          interBust: newVal.bodyMeasurementDto.interBust,
          frontBody: newVal.bodyMeasurementDto.frontBody,
          chestDistance: newVal.bodyMeasurementDto.chestDistance,
          length1: newVal.bodyMeasurementDto.length1,
          length2: newVal.bodyMeasurementDto.length2,
          length3: newVal.bodyMeasurementDto.length3,
          length4: newVal.bodyMeasurementDto.length4,
          aroundSleeve: newVal.bodyMeasurementDto.aroundSleeve,
          elbow: newVal.bodyMeasurementDto.elbow,
          wrist: newVal.bodyMeasurementDto.wrist,
          aroundNeck: newVal.bodyMeasurementDto.aroundNeck,
          backBody: newVal.bodyMeasurementDto.backBody,
          shoulders: newVal.bodyMeasurementDto.shoulders,
          back: newVal.bodyMeasurementDto.back,
          declineOfTheHips: newVal.bodyMeasurementDto.declineOfTheHips,
          sleeveLength: newVal.bodyMeasurementDto.sleeveLength,
        }
      },
    },
    // eslint-disable-next-line
        'bodyMeasurementDto.thorax': function (newVal) {
      this.radioStates.thorax = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.aroundBust': function (newVal) {
      this.radioStates.aroundBust = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.aroundUnderBust': function (newVal) {
      this.radioStates.aroundUnderBust = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.waist': function (newVal) {
      this.radioStates.waist = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.aroundHips': function (newVal) {
      this.radioStates.aroundHips = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.aroundLeg': function (newVal) {
      this.radioStates.aroundLeg = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.aroundKnee': function (newVal) {
      this.radioStates.aroundKnee = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.ankle': function (newVal) {
      this.radioStates.ankle = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.breastReduction': function (newVal) {
      this.radioStates.breastReduction = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.chestDistance': function (newVal) {
      this.radioStates.chestDistance = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.frontBody': function (newVal) {
      this.radioStates.frontBody = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.length1': function (newVal) {
      this.radioStates.length1 = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.length2': function (newVal) {
      this.radioStates.length2 = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.length3': function (newVal) {
      this.radioStates.length3 = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.length4': function (newVal) {
      this.radioStates.length4 = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.aroundSleeve': function (newVal) {
      this.radioStates.aroundSleeve = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.elbow': function (newVal) {
      this.radioStates.elbow = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.wrist': function (newVal) {
      this.radioStates.wrist = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.aroundNeck': function (newVal) {
      this.radioStates.aroundNeck = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.backBody': function (newVal) {
      this.radioStates.backBody = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.shoulders': function (newVal) {
      this.radioStates.shoulders = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.back': function (newVal) {
      this.radioStates.back = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.declineOfTheHips': function (newVal) {
      this.radioStates.declineOfTheHips = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.sleeveLength': function (newVal) {
      this.radioStates.sleeveLength = newVal !== '' && newVal !== null;
    },

  },
  mounted() {
    // console.log('as', this.id)
    // console.log('testt', this.getClient)
    // fillClientData()
  },

  methods: {
    ...mapActions(['editClient', 'getClientById']),
    validateState(name) {
      const { $dirty, $error } = this.$v.personal[name];
      return $dirty ? !$error : null;
    },
    // validateState2(name) {
    //   const { $dirty, $error } = this.$v.billingAddressInternalDto[name];
    //   return $dirty ? !$error : null;
    // },
    // validateState3(name) {
    //   const { $dirty, $error } = this.$v.shippingAddressInternalDto[name];
    //   return $dirty ? !$error : null;
    // },
    onCancelToggle() {
      this.$emit('onCancelToggle');
      this.$refs.modal.hide();
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.onReset();
    },
    onReset() {
      // Reset our form values
      this.formSubmitted = false
      this.personal = {}
      this.billingAddressInternalDto = {}
      this.shippingAddressInternalDto = {}
      this.bodyMeasurementDto = {}
      this.id = ''
      // Trick to reset/clear native browser form validation state
    },
    duplicateBillingAddress() {
      this.shippingAddressInternalDto.street = this.billingAddressInternalDto.street;
      this.shippingAddressInternalDto.houseNumber = this.billingAddressInternalDto.houseNumber;
      this.shippingAddressInternalDto.doorNumber = this.billingAddressInternalDto.doorNumber;
      this.shippingAddressInternalDto.postalCode = this.billingAddressInternalDto.postalCode;
      this.shippingAddressInternalDto.city = this.billingAddressInternalDto.city;
      this.shippingAddressInternalDto.country = this.billingAddressInternalDto.country;
      console.log(this.shippingAddressInternalDto)
    },
    getFormattedNumber(n) {
      if (n.isValid == true) {
        this.isPhoneNumberValid = true
        this.personal.telephoneNumber = n.nationalNumber
        this.personal.countryCode = n.countryCode
      } else {
        this.personal.countryCode = ''
        this.isPhoneNumberValid = false
        return;
      }
      console.log()
    },


    // fillClientData(){
    //   this.firstName= this.getClient.firstName
    // }
    // onReset(){
    //   this.personal.salutation = "",
    //   this.personal.firstName= "",
    //   this.personal.lastName= "",
    //   this.personal.gender= "",
    //   this.personal.email= "",
    //   this.personal.telephoneNumber= "",
    // },

    updateClientInfo() {
      this.formSubmitted = true;
      this.$v.personal.$touch();
      if (this.$v.personal.$anyError) {
        return;
      }
      this.$refs.modal.hide()
      let formattedDateOfBirth
      if (this.personal.dateOfBirth == null) {
        formattedDateOfBirth = null
      } else {
        formattedDateOfBirth = moment(this.personal.dateOfBirth, 'DD-MM-YYYY').format('YYYY-MM-DD');
      }

      const objekti = {
        ...this.personal,
        dateOfBirth: formattedDateOfBirth,
        billingAddressInternalDto: this.billingAddressInternalDto,
        shippingAddressInternalDto: this.shippingAddressInternalDto,
        bodyMeasurementInternalDto: this.bodyMeasurementDto,
      }
      this.$emit('onCancelToggle');
      this.$emit('updateClient', objekti)
      // await this.editClient({
      //   clientId: this.id,
      //   object: objekti,
      //   successCallback: () => {
      //   },
      // })
    },

  },
};
</script>

<style scoped lang="scss">
.pink-radio {
  margin-left: 6px;
}

.right {
  margin: 0px 6px 0px 0px !important;
}
 .save-btn1 {
        background: #FF274F !important;
        border: none;
        border-radius: 10px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        padding: 8px 46.08px;
        margin-top: 35px;
        width: 25%;
      }
.btn-con {
  display: flex;
}
.front-body {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 50px;
  padding-bottom: 20px;

  img {
    width: 100%;
  }
  .betweenCol{
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .input{
    max-width: 100%;
    height: 51px;
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
  .toraks {
    display: flex;
    position: absolute;
    top: 106px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .rrethiGjoksit {
    display: flex;
    position: absolute;
    top: 135px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .rrethiNenGjoksit {
    display: flex;
    position: absolute;
    top: 165px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .beli {
    display: flex;
    position: absolute;
    top: 200px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .vithet {
    display: flex;
    position: absolute;
    top: 263px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .rrethiKembes {
    display: flex;
    position: absolute;
    bottom: 222px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .rrethiGjurit {
    display: flex;
    position: absolute;
    bottom: 148px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .zoguKembeve {
    display: flex;
    position: absolute;
    bottom: 38px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .reniaGjoksit {
    display: flex;
    position: absolute;
    top: 85px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .nderGjoks {
    display: flex;
    position: absolute;
    top: 158px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .trupiPara {
    display: flex;
    position: absolute;
    top: 200px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .distancaGjoksit {
    display: flex;
    position: absolute;
    top: 128px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .gjatesia1 {
    display: flex;
    position: absolute;
    bottom: 222px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .gjatesia2 {
    display: flex;
    position: absolute;
    bottom: 127px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .gjatesia3 {
    display: flex;
    position: absolute;
    bottom: 68px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .gjatesia4 {
    display: flex;
    position: absolute;
    bottom: 8px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
}
.back-body {
  position: relative;

  img {
    width: 100%;
  }

  .rrethiMenges {
    display: flex;
    position: absolute;
    top: 126px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .brryli {
    display: flex;
    position: absolute;
    top: 156px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .kyçi {
    display: flex;
    position: absolute;
    top: 192px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .rrethiQafes {
    display: flex;
    position: absolute;
    top: 60px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .trupiPrapa {
    display: flex;
    position: absolute;
    top: 202px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .supet {
    display: flex;
    position: absolute;
    top: 97px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .shpina {
    display: flex;
    position: absolute;
    top: 135px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .reniaVitheve {
    display: flex;
    position: absolute;
    bottom: 253px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .gjatesiaMenges {
    display: flex;
    position: absolute;
    top: 95px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
}
.transparent {
  opacity: 0.5;
}
.backButton {
  /* Button styles */
  background: linear-gradient(52deg, rgb(38, 46, 108) 0%, rgb(155, 42, 92) 100%, rgb(255, 39, 79) 100%) 0% 0% no-repeat padding-box padding-box transparent;
    color: rgb(244, 241, 237);
  padding: 10px 80px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  // width: 200px;
  // margin-left: 75px;
  text-decoration: none;
  // margin-top: 70px;
}

.backButton:hover {
  /* Hover styles */
  background-color: #0056b3;
}

.backButton:active {
  /* Active (clicked) styles */
  background-color: #003080;
}
.patient-profile-container {
  font-family: "Montserrat";
  margin-left: 0px;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 32px;
  .buttons-container {
    max-width: 1140px;
    width: 100%;
    padding: 15px 0 10px 0;
    display: flex;
    justify-content: flex-end;
    button {
      background: #242f6e;
      border: none;
      border-radius: 10px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      padding: 8px 22px;
    }
  }
  .edit-container {
    max-width: 1134px;
    width: 100%;
    display: flex;
    text-align: left;
    justify-content: center;
    padding-top: 36px;
    padding-bottom: 38.5px;
    border-top: 1px solid black;
    .first-container {
      .header {
        margin-bottom: 29px;
        h1 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
          color: #000000;
        }
      }
      form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 28px;
        grid-row-gap: 39px;
      }
      .form-group {
        margin-bottom: 0px;
      }
      .form-control {
        // background-color: #e8e8e8 !important;
        // box-shadow: none;
        margin-top: 5px;
      }
      .flex-form {
        display: flex;
        flex-direction: column;
      }
      select {
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        // background-color: #e8e8e8 !important;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        -moz-appearance: none;
        border-radius: 0.375rem;
        outline: none;
        box-shadow: none;
      }
      .save-btn {
        background: #FF274F !important;
        border: none;
        border-radius: 10px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        padding: 8px 46.08px;
        margin-top: 35px;
      }
    }
    .second-container {
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 29px;
        h1 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
          color: #000000;
        }
        button {
          background: #242f6e;
          border: none;
          border-radius: 10px;
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #ffffff;
          padding: 8px 22px;
        }
      }
      .files-container {
        height: 514px;
        display: block;
        overflow: auto;
        background: #e8e8e8;
        // box-shadow: none;,
          // 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 30px 20px 0;
        .file {
          background: #ffffff;
          // box-shadow: none;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 12px;
          margin-bottom: 15px;
          .left-side {
            display: flex;
            align-items: center;

            img {
              margin-right: 15px;
            }
            .file-name {
              margin-bottom: 0;
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 500;
              font-size: 15px;
              line-height: 18px;
              color: #000000;
            }
          }
          .right-side {
            display: flex;
            align-items: center;

            img {
            }
            .date {
              margin-bottom: 0;
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 15px;
              color: #5c636a;
              margin-right: 15px;
            }
          }
        }
      }
      .files-container::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .patient-history-container {
    max-width: 1134px;
    width: 100%;
    display: grid;
    grid-auto-flow: row;
    grid-column-gap: 88px;
    grid-row-gap: 27px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
    text-align: left;
    justify-content: center;
    padding-top: 16.5px;
    border-top: 1px solid black;
    margin-bottom: 60px;
    .first-container {
      .header {
        margin-bottom: 29px;
        h1 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
          color: #000000;
        }
      }
      form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 28px;
        grid-row-gap: 39px;
      }
      .form-group {
        margin-bottom: 0px;
      }
      .form-control {
        // background-color: #e8e8e8 !important;
        // box-shadow: none;
      }
      .flex-form {
        display: flex;
        flex-direction: column;
      }
      select {
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        // background-color: #e8e8e8 !important;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        -moz-appearance: none;
        border-radius: 0.375rem;
        outline: none;
        // box-shadow: none;
      }
      .save-btn {
        background: #FF274F !important;
        border: none;
        border-radius: 10px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        padding: 8px 46.08px;
        margin-top: 35px;
      }
    }
    .second-container {
      .header {
        margin-bottom: 29px;
        h1 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
          color: #000000;
        }
      }
      form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 28px;
        grid-row-gap: 39px;
      }
      .form-group {
        margin-bottom: 0px;
      }
      .form-control {
        // background-color: #e8e8e8 !important;
        // box-shadow: none;
      }
      .flex-form {
        display: flex;
        flex-direction: column;
      }
      select {
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        // background-color: #e8e8e8 !important;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        -moz-appearance: none;
        border-radius: 0.375rem;
        outline: none;
        // box-shadow: none;
      }
      .save-btn {
        background: #FF274F !important;
        border: none;
        border-radius: 10px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        padding: 8px 46.08px;
        margin-top: 35px;
      }
    }
  }
}
@media only screen and (max-width: 1100px) {
  .patient-profile-container {
    .edit-container {
      grid-auto-flow: row;
      grid-column-gap: 88px;
      grid-row-gap: 27px;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(2, 1fr);

      .second-container {
        margin-bottom: 30px;
      }
    }
  }
}
@media only screen and (max-width: 1181px) {
  .patient-profile-container {
    margin-left: 0;
    margin-top: 60px;
  }
}
@media only screen and (max-width: 620px) {
}
@media only screen and (max-width: 499px) {
  .patient-profile-container {
    padding: 0 16px;
    .buttons-container {
      padding: 59px 0 10px 0;
      button {
      }
    }
    .edit-container {
      grid-row-gap: 54px;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, 1fr);

      .first-container {
        .header {
          h1 {
          }
        }
        form {
          grid-template-columns: none;
          grid-row-gap: 20px;
        }
        .form-group {
        }
        .form-control {
        }
        .flex-form {
        }
        select {
        }
      }
      .second-container {
        .header {
          h1 {
          }
          button {
          }
        }
        .files-container {
          padding: 20px 10px 0;
          .file {
            .left-side {
              img {
                margin-right: 7.5px;
              }
              .file-name {
                margin-bottom: 0;
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                color: #000000;
              }
            }
            .right-side {
              img {
              }
              .date {
                font-size: 10px;
                line-height: 15px;
                margin-right: 7.5px;
              }
            }
          }
        }
        .files-container::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}
</style>
