<template>
  <div>
    <b-modal
      id="view-client"
      ref="modal"
      header-class="headerModal"
      :title="`${client.firstName} ${client.lastName} ${$t('Details')} `"
      centered
      :size="client.bodyMeasurementDto != null ? 'xl' : 'lg'"
      hide-footer
      no-close-on-backdrop
      @close="onCancel"
    >
      <b-tabs
        v-model="tabIndex"
        card
      >
        <b-tab
          active
        >
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("ClientInformation") }}</strong>
          </template>
          <b-card-text><b-form disabled>
            <b-form-group
              id="input-group-1"
              :label="`${$t('Salutation')}:`"
              label-for="input-1"
            >
              <vue-select
                v-model="client.salutation"
                disabled
              />
            </b-form-group>
            <b-form-group
              id="input-group-1"
              :label="`${$t('FirstName')}:`"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="client.firstName"
                disabled
              />
            </b-form-group>
            <b-form-group
              id="input-group-1"
              :label="`${$t('LastName')}:`"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="client.lastName"
                disabled
              />
            </b-form-group>
            <b-form-group
              id="input-group-2"
              :label="`${$t('Gender')}:`"
              label-for="input-2"
            >
              <vue-select
                v-model="client.gender"
                disabled
              />
            </b-form-group>
            <b-form-group
              id="input-group-9"
              :label="`${$t('PhoneNumber')}:`"
              label-for="input-9"
            >
              <vue-phone-number-input
                v-model="client.telephoneNumber"
                :default-country-code="client.countryCode"
                :preferred-countries="['AL', 'CH', 'DE']"
                :no-example="true"
                disabled
              />
            </b-form-group>
            <b-form-group
              id="input-group-9"
              :label="`${$t('Email')}:`"
              label-for="input-9"
            >
              <b-form-input
                id="input-9"
                v-model="client.email"
                disabled
              />
            </b-form-group>
            <b-form-group
              id="input-group-9"
              :label="`${$t('DateofBirth')}:`"
              label-for="input-9"
            >
              <b-form-input
                id="input-9"
                v-model="formatedDate"
                disabled
              />
            </b-form-group>
          </b-form>
          </b-card-text>
        </b-tab>
        <b-tab v-if="client.shippingAddressDto != null">
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("ShippingAddress") }}</strong>
          </template>
          <b-card-text>
            <b-form>
              <b-form-group
                id="input-group-3"
                :label="`${$t('Street')}:`"
                label-for="input-3"
              >
                <b-form-input
                  id="input-3"
                  v-model="client.shippingAddressDto.street"
                  disabled
                />
              </b-form-group>

              <b-form-group
                id="input-group-6"
                :label="`${$t('Door/HouseNumber')}:`"
                label-for="input-6"
              >
                <b-form-input
                  id="input-6"
                  v-model="client.shippingAddressDto.doorNumber"
                  disabled
                />
              </b-form-group>
              <b-form-group
                id="input-group-6"
                :label="`${$t('PostalCode')}:`"
                label-for="input-6"
              >
                <b-form-input
                  id="input-6"
                  v-model="client.shippingAddressDto.postalCode"
                  disabled
                />
              </b-form-group>
              <b-form-group
                id="input-group-5"
                :label="`${$t('City')}:`"
                label-for="input-5"
              >
                <b-form-input
                  id="input-5"
                  v-model="client.shippingAddressDto.city"
                  disabled
                />
              </b-form-group>
              <b-form-group
                id="input-group-7"
                :label="`${$t('Country')}:`"
                label-for="input-7"
              >
                <b-form-input
                  id="input-7"
                  v-model="client.shippingAddressDto.country"
                  disabled
                />
              </b-form-group>
            </b-form>
          </b-card-text>
        </b-tab>
        <b-tab v-if="client.billingAddressDto != null">
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("BillingAddress") }}</strong>
          </template>
          <b-card-text>
            <b-form>
              <b-form-group
                id="input-group-3"
                :label="`${$t('Street')}:`"
                label-for="input-3"
              >
                <b-form-input
                  id="input-3"
                  v-model="client.billingAddressDto.street"
                  disabled
                />
              </b-form-group>

              <b-form-group
                id="input-group-6"
                :label="`${$t('Door/HouseNumber')}:`"
                label-for="input-6"
              >
                <b-form-input
                  id="input-6"
                  v-model="client.billingAddressDto.doorNumber"
                  disabled
                />
              </b-form-group>
              <b-form-group
                id="input-group-6"
                :label="`${$t('PostalCode')}:`"
                label-for="input-6"
              >
                <b-form-input
                  id="input-6"
                  v-model="client.billingAddressDto.postalCode"
                  disabled
                />
              </b-form-group>
              <b-form-group
                id="input-group-5"
                :label="`${$t('City')}:`"
                label-for="input-5"
              >
                <b-form-input
                  id="input-5"
                  v-model="client.billingAddressDto.city"
                  disabled
                />
              </b-form-group>
              <b-form-group
                id="input-group-7"
                :label="`${$t('Country')}:`"
                label-for="input-7"
              >
                <b-form-input
                  id="input-7"
                  v-model="client.billingAddressDto.country"
                  disabled
                />
              </b-form-group>
            </b-form>
          </b-card-text>
        </b-tab>
        <b-tab
          v-if="client.bodyMeasurementDto != null"
        >
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("Sizes") }}</strong>
          </template>
          <div
            v-if="clienti =='ValdrinSahiti'"
            class="skica-container"
            style="padding-top: 15px;"
          >
            <b-collapse
              :id="`collapse`"
              visible
            >
              <div
                class="front-body"
              >

                <div class="betweenCol">
                  <div class="input">
                    <span>{{ $t('toraks') }}</span>
                    <b-form-input
                      v-model="client.bodyMeasurementDto.thorax"
                      disabled
                      :placeholder="$t('')"
                      class="toraks1"
                    />
                  </div>
                  <div class="input">
                    <span>{{ $t('Bust') }}</span>
                    <b-form-input
                      v-model="client.bodyMeasurementDto.aroundBust"
                      disabled
                      :placeholder="$t('')"
                      class="rrethiGjoksit1"
                    />
                  </div>
                  <div class="input">
                    <span>{{ $t('UnderBust') }}</span>
                    <b-form-input
                      v-model="client.bodyMeasurementDto.aroundUnderBust"
                      disabled
                      :placeholder="$t('')"
                      class="rrethiNenGjoksit1"
                    />
                  </div>
                  <div class="input">
                    <span>{{ $t('Abdomen') }}</span>
                    <b-form-input
                      v-model="client.bodyMeasurementDto.waist"
                      disabled
                      :placeholder="$t('')"
                      class="beli1"
                    />
                  </div>
                  <div class="input">
                    <span>{{ $t('Hips') }}</span>
                    <b-form-input
                      v-model="client.bodyMeasurementDto.aroundHips"
                      disabled
                      :placeholder="$t('')"
                      class="vithet1"
                    />
                  </div>
                  <div class="input">
                    <span>{{ $t('BustReduction') }}</span>
                    <b-form-input
                      v-model="client.bodyMeasurementDto.aroundLeg"
                      disabled
                      :placeholder="$t('')"
                      class="rrethiKembes1"
                    />
                  </div>
                  <div class="input">
                    <span>{{ $t('UnderBustReduction') }}</span>
                    <b-form-input
                      v-model="client.bodyMeasurementDto.aroundKnee"
                      disabled
                      :placeholder="$t('')"
                      class="rrethiGjurit1"
                    />
                  </div>
                  <div class="input">
                    <span>{{ $t('FrontBody') }}</span>
                    <b-form-input
                      v-model="client.bodyMeasurementDto.ankle"
                      disabled
                      :placeholder="$t('')"
                      class="zoguKembeve1"
                    />
                  </div>


                </div>
                <div class="betweenCol">


                  <div class="input">
                    <span>{{ $t('BackBody') }}</span>
                    <b-form-input
                      v-model="client.bodyMeasurementDto.breastReduction"
                      disabled
                      :placeholder="$t('')"
                      class="reniaGjoksit1"
                    />
                  </div>
                  <div class="input">
                    <span>{{ $t('Shoulders') }}</span>
                    <b-form-input
                      v-model="client.bodyMeasurementDto.chestDistance"
                      disabled
                      :placeholder="$t('')"
                      class="distancaGjoksit1"
                    />
                  </div>
                  <div class="input">
                    <span>{{ $t('ShouldersSeam') }}</span>
                    <b-form-input
                      v-model="client.bodyMeasurementDto.sleeveLength"
                      disabled
                      :placeholder="$t('')"
                      class="distancaGjoksit1"
                    />
                  </div>
                  <div class="input">
                    <span>{{ $t('Distance') }}</span>
                    <b-form-input
                      v-model="client.bodyMeasurementDto.interBust"
                      disabled
                      :placeholder="$t('')"
                      class="nderGjoks1"
                    />
                  </div>

                  <div class="input">
                    <span>{{ $t('ButtocksReduction') }}</span>
                    <b-form-input
                      v-model="client.bodyMeasurementDto.frontBody"
                      disabled
                      :placeholder="$t('')"
                      class="trupiPara1"
                    />
                  </div>
                  <div class="input">
                    <span>{{ $t('Height') }}</span>
                    <b-form-input
                      v-model="client.bodyMeasurementDto.length1"
                      disabled
                      :placeholder="$t('')"
                      class="gjatesia11"
                    />
                  </div>
                  <div class="input">
                    <span>{{ $t('3QuartersLength') }}</span>
                    <b-form-input
                      v-model="client.bodyMeasurementDto.length2"
                      disabled
                      :placeholder="$t('')"
                      class="gjatesia21"
                    />
                  </div>
                </div>
                <div class="betweenCol">

                  <div class="input">
                    <span>{{ $t('ChanelLength') }}</span>
                    <b-form-input
                      v-model="client.bodyMeasurementDto.length3"
                      disabled
                      :placeholder="$t('')"
                      class="gjatesia31"
                    />
                  </div>
                  <div class="input">
                    <span>{{ $t('MiniLength') }}</span>
                    <b-form-input
                      v-model="client.bodyMeasurementDto.length4"
                      disabled
                      :placeholder="$t('')"
                      class="gjatesia41"
                    />
                  </div>
                  <div class="input">
                    <span>{{ $t('SleeveCircumference') }}</span>
                    <b-form-input
                      v-model="client.bodyMeasurementDto.aroundSleeve"
                      disabled
                      :placeholder="$t('')"
                      class="rrethiMenges1"
                    />
                  </div>
                  <div class="input">
                    <span>{{ $t('SleeveLength') }}</span>
                    <b-form-input
                      v-model="client.bodyMeasurementDto.elbow"
                      disabled
                      :placeholder="$t('')"
                      class="brryli1"
                    />
                  </div>
                  <div class="input">
                    <span>{{ $t('M.3QuartersLength') }}</span>
                    <b-form-input
                      v-model="client.bodyMeasurementDto.wrist"
                      disabled
                      :placeholder="$t('')"
                      class="kyçi1"
                    />
                  </div>
                  <div class="input">
                    <span>{{ $t('ElbowCircumference') }}</span>
                    <b-form-input
                      v-model="client.bodyMeasurementDto.aroundNeck"
                      disabled
                      :placeholder="$t('')"
                      class="rrethiQafes1"
                    />
                  </div>
                  <div class="input">
                    <span>{{ $t('WristCircumference') }}</span>
                    <b-form-input
                      v-model="client.bodyMeasurementDto.backBody"
                      disabled
                      :placeholder="$t('')"
                      class="trupiPrapa1"
                    />
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
          <div
            v-else
            class="skica-container"
            style="padding-top: 15px;"
          >
            <b-collapse
              :id="`collapse`"
              visible
            >
              <div
                class="btn-con"
              >
                <button
                  type="button"
                  @click="showBody = !showBody"
                >
                  {{ $t('FlipBody') }}
                </button>
              </div>
              <!-- v-if="dress.showFrontBody" -->
              <div
                v-if="showBody "
                class="front-body"
              >
                <img
                  src="../assets/images/front-body.svg"
                  alt=""
                >
                <div class="toraks">
                  <span>1.</span>
                  <!-- v-model="client.bodyMeasurementDto.thorax" -->
                  <b-form-input
                    v-model="client.bodyMeasurementDto.thorax"
                    disabled
                    :placeholder="$t('toraks')"
                    class="toraks1"
                  />
                </div>
                <div class="rrethiGjoksit">
                  <span>2.</span>
                  <!-- v-model="client.bodyMeasurementDto.aroundBust" -->
                  <b-form-input
                    v-model="client.bodyMeasurementDto.aroundBust"
                    disabled
                    :placeholder="$t('rrethiGjoksit')"
                    class="rrethiGjoksit1"
                  />
                </div>
                <div class="rrethiNenGjoksit">
                  <span>3.</span>
                  <!-- v-model="client.bodyMeasurementDto.aroundUnderBust" -->
                  <b-form-input
                    v-model="client.bodyMeasurementDto.aroundUnderBust"
                    disabled
                    :placeholder="$t('rrethiNenGjoksit')"
                    class="rrethiNenGjoksit1"
                  />
                </div>
                <div class="beli">
                  <span>4.</span>
                  <!-- v-model="client.bodyMeasurementDto.waist" -->
                  <b-form-input
                    v-model="client.bodyMeasurementDto.waist"
                    disabled
                    :placeholder="$t('beli')"
                    class="beli1"
                  />
                </div>
                <div class="vithet">
                  <span>5.</span>
                  <!-- v-model="client.bodyMeasurementDto.aroundHips" -->
                  <b-form-input
                    v-model="client.bodyMeasurementDto.aroundHips"
                    disabled
                    :placeholder="$t('RrethiVitheve')"
                    class="vithet1"
                  />
                </div>
                <div class="rrethiKembes">
                  <span>6.</span>
                  <!-- v-model="client.bodyMeasurementDto.aroundLeg" -->
                  <b-form-input
                    v-model="client.bodyMeasurementDto.aroundLeg"
                    disabled
                    :placeholder="$t('rrethiKembes')"
                    class="rrethiKembes1"
                  />
                </div>
                <div class="rrethiGjurit">
                  <span>7.</span>
                  <b-form-input
                    v-model="client.bodyMeasurementDto.aroundKnee"
                    disabled
                    :placeholder="$t('rrethiGjurit')"
                    class="rrethiGjurit1"
                  />
                </div>
                <div class="zoguKembeve">
                  <span>8.</span>
                  <b-form-input
                    v-model="client.bodyMeasurementDto.ankle"
                    disabled
                    :placeholder="$t('zoguKembeve')"
                    class="zoguKembeve1"
                  />
                </div>

                <div class="reniaGjoksit">
                  <span>9.</span>
                  <b-form-input
                    v-model="client.bodyMeasurementDto.breastReduction"
                    disabled
                    :placeholder="$t('reniaGjoksit')"
                    class="reniaGjoksit1"
                  />
                </div>
                <div class="distancaGjoksit">
                  <span>10.</span>
                  <b-form-input
                    v-model="client.bodyMeasurementDto.chestDistance"
                    disabled
                    :placeholder="$t('distancaGjoksit')"
                    class="distancaGjoksit1"
                  />
                </div>
                <div class="nderGjoks">
                  <span>11.</span>
                  <b-form-input
                    v-model="client.bodyMeasurementDto.interBust"
                    disabled
                    :placeholder="$t('nderGjoks')"
                    class="nderGjoks1"
                  />
                </div>
                <div class="trupiPara">
                  <span>12.</span>
                  <b-form-input
                    v-model="client.bodyMeasurementDto.frontBody"
                    disabled
                    :placeholder="$t('trupiPara')"
                    class="trupiPara1"
                  />
                </div>
                <div class="gjatesia1">
                  <span>13.</span>
                  <b-form-input
                    v-model="client.bodyMeasurementDto.length1"
                    disabled
                    :placeholder="$t('gjatesia1')"
                    class="gjatesia11"
                  />
                </div>
                <div class="gjatesia2">
                  <span>14.</span>
                  <b-form-input
                    v-model="client.bodyMeasurementDto.length2"
                    disabled
                    :placeholder="$t('gjatesia2')"
                    class="gjatesia21"
                  />
                </div>
                <div class="gjatesia3">
                  <span>15.</span>
                  <b-form-input
                    v-model="client.bodyMeasurementDto.length3"
                    disabled
                    :placeholder="$t('gjatesia3')"
                    class="gjatesia31"
                  />
                </div>
                <div class="gjatesia4">
                  <span>16.</span>
                  <b-form-input
                    v-model="client.bodyMeasurementDto.length4"
                    disabled
                    :placeholder="$t('gjatesia4')"
                    class="gjatesia41"
                  />
                </div>
              </div>
              <!-- v-if="!dress.showFrontBody" -->
              <div
                v-if="!showBody"
                class="back-body"
              >
                <img
                  src="../assets/images/back-body.svg"
                  alt=""
                >
                <div class="rrethiMenges">
                  <span>9.</span>
                  <b-form-input
                    v-model="client.bodyMeasurementDto.aroundSleeve"
                    disabled
                    :placeholder="$t('rrethiMenges')"
                    class="rrethiMenges1"
                  />
                </div>
                <div class="brryli">
                  <span>10.</span>
                  <b-form-input
                    v-model="client.bodyMeasurementDto.elbow"
                    disabled
                    :placeholder="$t('brryli')"
                    class="brryli1"
                  />
                </div>
                <div class="kyçi">
                  <span>11.</span>
                  <b-form-input
                    v-model="client.bodyMeasurementDto.wrist"
                    disabled
                    :placeholder="$t('kyqi')"
                    class="kyçi1"
                  />
                </div>
                <div class="rrethiQafes">
                  <span>12.</span>
                  <b-form-input
                    v-model="client.bodyMeasurementDto.aroundNeck"
                    disabled
                    :placeholder="$t('rrethiQafes')"
                    class="rrethiQafes1"
                  />
                </div>
                <div class="trupiPrapa">
                  <span>16.</span>
                  <b-form-input
                    v-model="client.bodyMeasurementDto.backBody"
                    disabled
                    :placeholder="$t('trupiPrapa')"
                    class="trupiPrapa1"
                  />
                </div>
                <div class="supet">
                  <span>17.</span>
                  <b-form-input
                    v-model="client.bodyMeasurementDto.shoulders"
                    disabled
                    :placeholder="$t('supet')"
                    class="supet1"
                  />
                </div>
                <div class="shpina">
                  <span>18.</span>
                  <b-form-input
                    v-model="client.bodyMeasurementDto.back"
                    disabled
                    :placeholder="$t('shpina')"
                    class="shpina1"
                  />
                </div>
                <div class="reniaVitheve">
                  <span>20.</span>
                  <b-form-input
                    v-model="client.bodyMeasurementDto.declineOfTheHips"
                    disabled
                    :placeholder="$t('reniaVitheve')"
                    class="reniaVitheve1"
                  />
                </div>
                <div class="gjatesiaMenges">
                  <span>21.</span>
                  <b-form-input
                    v-model="client.bodyMeasurementDto.sleeveLength"
                    disabled
                    :placeholder="$t('gjatesiaMenges')"
                    class="gjatesiaMenges1"
                  />
                </div>
              </div>
            </b-collapse>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>
    <!-- <vue-easy-lightbox
      :esc-disabled="false"
      move-disabled
      :visible="visible"
      :imgs="getPhotosWithSrc"
      @hide="handleHide"
    /> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// import flatPickr from 'vue-flatpickr-component';
import { validationMixin } from 'vuelidate';
// import AWS from 'aws-sdk';
// import VueEasyLightbox from 'vue-easy-lightbox';
// or
import moment from 'moment'
import { client } from '../domainConfig'

export default {
  components: {
    // flatPickr,
    // VueEasyLightbox,
  },
  mixins: [validationMixin],
  // eslint-disable-next-line vue/require-prop-types
  props: ['client'],
  data() {
    return {
      clienti: client.clientName,
      visible: false,
      userId: null,
      showBody: true,
      imagePassport: [],
      imageSocialNumber: [],
      imageResidencePermit: [],
      deleteButton: false,
      isTextShown: false,
      hoveredIndex: null,
      text: 'Click to zoom the photo',
      tabIndex: 1,
      showError: false,
      roleOptions: ['CEO', 'TeamLeader', 'Admin', 'Manager', 'Employee'],
      s3: null,
      // bucketName: url.DsLogistikBucketName,
      canton: [],
      fileDataPassport: [],
      fileDataAufenthaltsbewilligung: [],
      fileDataAHV: [],
    };
  },
  computed: {
    ...mapGetters([
      // 'getCantons',
      // 'getUserInformation',
      // 'getPhotos',
    ]),
    formatedDate() {
      let date
      if (this.client.dateOfBirth != null) {
        date = moment(this.client.dateOfBirth).format('DD-MM-YYYY')
      } else {
        date = '-'
      }
      // const date = moment(this.client.dateOfBirth)
      return date
    },
    // userById() {
    //   return this.$store.getters.getUserInformation;
    // },
    // getPhotosWithSrc() {
    //   return this.getPhotos.map((photo) => {
    //     return {
    //       src: photo.url,
    //       caption: photo.fileName,
    //     }
    //   });
    // },
  },
  methods: {
    ...mapActions([
      'loadCountryStates',
      'deleteImage',
      // 'getPhotosByDocumentId',
    ]),
    // async LoadImagesPassport() {
    //   await this.getPhotosByDocumentId(this.imagePassport[0].documentId);
    //   this.show()
    // },
    // async LoadImagesAufenthaltsbewilligung() {
    //   await this.getPhotosByDocumentId(this.imageResidencePermit[0].documentId);
    //   this.show()
    // },
    // async LoadImagesAHV() {
    //   await this.getPhotosByDocumentId(this.imageSocialNumber[0].documentId);
    //   this.show();
    // },
    handleHide() {
      this.visible = false
    },
    onCancel() {
      this.$refs.modal.hide();
      this.imagePassport = []
      this.imageResidencePermit = []
      this.imageSocialNumber = []
    },
    show() {
      this.visible = true
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets//scss//variables";
.front-body {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 50px;
  padding-bottom: 20px;

  img {
    width: 100%;
  }
  .betweenCol{
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .input{
    max-width: 100%;
    height: 51px;
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
  .toraks {
    display: flex;
    position: absolute;
    top: 106px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .rrethiGjoksit {
    display: flex;
    position: absolute;
    top: 135px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .rrethiNenGjoksit {
    display: flex;
    position: absolute;
    top: 165px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .beli {
    display: flex;
    position: absolute;
    top: 200px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .vithet {
    display: flex;
    position: absolute;
    top: 263px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .rrethiKembes {
    display: flex;
    position: absolute;
    bottom: 222px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .rrethiGjurit {
    display: flex;
    position: absolute;
    bottom: 148px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .zoguKembeve {
    display: flex;
    position: absolute;
    bottom: 38px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .reniaGjoksit {
    display: flex;
    position: absolute;
    top: 85px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .nderGjoks {
    display: flex;
    position: absolute;
    top: 158px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .trupiPara {
    display: flex;
    position: absolute;
    top: 200px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .distancaGjoksit {
    display: flex;
    position: absolute;
    top: 128px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .gjatesia1 {
    display: flex;
    position: absolute;
    bottom: 222px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .gjatesia2 {
    display: flex;
    position: absolute;
    bottom: 127px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .gjatesia3 {
    display: flex;
    position: absolute;
    bottom: 68px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .gjatesia4 {
    display: flex;
    position: absolute;
    bottom: 8px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
}
.back-body {
  position: relative;

  img {
    width: 100%;
  }

  .rrethiMenges {
    display: flex;
    position: absolute;
    top: 126px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .brryli {
    display: flex;
    position: absolute;
    top: 156px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .kyçi {
    display: flex;
    position: absolute;
    top: 192px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .rrethiQafes {
    display: flex;
    position: absolute;
    top: 60px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .trupiPrapa {
    display: flex;
    position: absolute;
    top: 202px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .supet {
    display: flex;
    position: absolute;
    top: 97px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .shpina {
    display: flex;
    position: absolute;
    top: 135px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .reniaVitheve {
    display: flex;
    position: absolute;
    bottom: 253px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .gjatesiaMenges {
    display: flex;
    position: absolute;
    top: 95px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
}
 .photo{
    margin-left: 5px;
    height: 50px;
    border-radius: 8px;
  }
.second-photo{
  margin-left: 20px;
}
.hovered {
  /* Additional styles for the hovered photo */
  transform: scale(7.2);
  border-radius: 0px;
  z-index: 10;
}
.third-photo{
  margin-left: 20px;
}
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  align-items: flex-end;
}
.form {
  display: grid;
  grid-template-columns: 1fr 1fr ;
  gap: 10px;
}
.form2 {
  display: grid;
  grid-template-columns: 1fr 1fr ;
  gap: 10px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons{
  display: flex;
  align-items:flex-end;
}
</style>
