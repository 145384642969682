<template>
  <div>
    <b-modal
      id="edit-characteristics"
      ref="modal"
      header-class="headerModal"
      no-close-on-backdrop
      centered
      title="Add Characteristics"
      hide-footer
      @close="onCancel"
    >
      <div style="display: flex; justify-content: space-between;">
        <b-form-group
          id="input-group-4"
          :label="$t('favoriteColor')"
          label-for="input-9"
        >
          <b-form-input
            v-model="form.favoriteColor"
            :placeholder="$t('Color')"
            style="width: 224px;"
          />
        </b-form-group>
        <b-form-group
          id="input-group-1"
          :label="$t('favoriteDress')"
          label-for="input-1"
        >
          <b-form-input
            v-model="form.favoriteDress"
            :placeholder="$t('Dress')"
            style="width: 224px;"
          />
        </b-form-group>
      </div>
      <div>
        <b-form-group
          id="input-group-4"
          :label="`${$t('generalDescription')}:`"
          label-for="input-4"
        >
          <b-form-textarea
            id="input-4"
            v-model="form.generalDescription"
            :placeholder="$t('Notes')"
            aria-describedby="input-1-live-feedback"
          />
        </b-form-group>
      </div>
      <div class="buttonsEverywhere">
        <button
          type="button"
          variant="none"
          style="margin-left: 0px;"
          class="buttonSubmit"
          @click="onSubmit()"
        >
          {{ $t('Confirm') }}
        </button>
        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; background: white; color: black; border-color: white;font-weight: 400;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal></div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  components: {
  },
  props: ['clientId', 'characteristics'],
  data() {
    return {
      form: {
        generalDescription: null,
        favoriteDress: null,
        favoriteColor: null,
      },
    }
  },
  watch: {
    characteristics(value) {
      this.form.favoriteColor = value.favoriteColor
      this.form.favoriteDress = value.favoriteDress
      this.form.generalDescription = value.generalDescription
    },
  },
  methods: {
    ...mapActions(['loadSaturdayWork']),
    onSubmit() {
      const objekti = {
        ...this.form,
      }
      this.$emit('editCharacter', objekti);
      this.$refs.modal.hide()
      this.onReset()
    },
    onCancel() {
      this.$refs.modal.hide();
      this.onReset()
    },
    onReset() {
      if (this.form.generalDescription == null || this.form.generalDescription == '') {
        this.form.generalDescription = this.characteristics.generalDescription
      }
      if (this.form.favoriteDress == null || this.form.favoriteDress == '') {
        this.form.favoriteDress = this.characteristics.favoriteDress
      }
      if (this.form.favoriteColor == null || this.form.favoriteColor == '') {
        this.form.favoriteColor = this.characteristics.favoriteColor
      }
    },
  },
}
</script>

  <style lang="scss" scoped>
    form {
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    }
    .deactivate-modal__select {
      display: flex;
      flex-direction: column;
      select {
        margin-bottom: 10px;
        padding: 10px;
      }
      button {
        align-self: end;
      }
    }
    .flex-form {
      display: flex;
      flex-direction: column;
    }
    .my-class {
    background: black;
    font-weight: bold;
  }

  </style>
