<template>
  <div>
    <b-modal
      id="potential-dress-for-client"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('Potential Dress')"
      size="xl"
      hide-footer
      @close="onCancel"
      @click="onReset"
    >
      <b-form>
        <!-- <p>{{ $t('CancelVacation') }}</p> -->
        <div
          style="display: flex; justify-content: space-between; padding: 0px 15px"
        >
          <div style="width: 30%">
            <b-form-group
              id="input-group-8"
              :label="$t('ArticleName')"
              style="width: 100%; margin-right: 10px"
              label-for="input-25"
            >
              <vue-select
                id="input-5"
                v-model="articlename"
                :options="getProductsInStock"
                label="articleNumber"
                :placeholder="$t('Select')"
                aria-describedby="input-1-live-feedback"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center"
                >
                  <button
                    type="button"
                    :style="
                      page == 1
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242f6e; color: white'
                    "
                    :disabled="page == 1"
                    @click="prev"
                  >
                    {{ $t("Prev") }}
                  </button>
                  <button
                    :style="
                      getProductsInStock.length < 10
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242f6e; color: white'
                    "
                    type="button"
                    :disabled="getProductsInStock.length < 10"
                    @click="next"
                  >
                    <!-- :disabled="page == getTotalItemsForOrders" -->
                    {{ $t("Next") }}
                  </button>
                </li>
              </vue-select>
              <!-- :reduce="(e) => e.articleNumber" -->
            </b-form-group>
            <img
              v-if="getimagesProductionManagement == null ? null : getimagesProductionManagement[0]"
              style="height: 200px; border-radius: 3px"
              :src="getimagesProductionManagement[0] ? getimagesProductionManagement[0].url : ''"
              alt=""
              @click.stop="getAllImages(color)"
            >
            <p
              v-else
              style="margin-top: 20px"
            >
              {{ articlename ? `This Article Number doesn't have photo` : "" }}
            </p>
          </div>
          <div style="display: flex; flex-direction: column">
            <b-form-group
              id="input-group-1"
              :label="`${$t('rrethiGjoksit')}:`"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="aroundBust"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t("This_is_a_required_field_and_must_be_at_least_3_letters")
              }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-1"
              :label="`${$t('beli')}:`"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="waist"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t("This_is_a_required_field_and_must_be_at_least_3_letters")
              }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-1"
              :label="`${$t('RrethiVitheve')}:`"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="aroundHips"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t("This_is_a_required_field_and_must_be_at_least_3_letters")
              }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-1"
              :label="`${$t('Length')}:`"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="length1"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t("This_is_a_required_field_and_must_be_at_least_3_letters")
              }}</b-form-invalid-feedback>
            </b-form-group>
            <b-button
              type="button"
              variant="none"
              style="margin-top: 10px;"

              class="buttonSubmit"
              @click="findClient"
            >
              {{ $t("FindClient") }}
            </b-button>
          </div>
        </div>
        <div style="width: 100%; border-bottom: 1px solid gray; margin: 10px 0px" />
        <div style="display: flex; justify-content: space-around">
          <!-- <div
            v-for="client in getClientCompare"
            :key="client.clientId"
            style="display: flex;"
          >
            <div style="border: 1px solid gray; border-radius: 10px; padding: 5px; margin: 5px;">
              {{ client.firstName }}
              {{ client.lastName }}
            </div>
          </div> -->
          <div
            v-if="getClientCompare.length>0"
            style="display: flex; flex-direction: column; width: 100%"
          >
            <table
              responsive
              class="team_table"
            >
              <thead style="border-bottom: 1px solid #dcdfe6; color: #606266; text-align: left">
                <tr>
                  <th>
                    {{ $t("FullName") }}
                  </th>
                  <th>
                    {{ $t("Gender") }}
                  </th>
                  <th>
                    {{ $t("Email ") }}
                  </th>
                  <th>
                    {{ $t("PhoneNumber") }}
                  </th>
                  <th>
                    {{ $t("Discount") }}
                  </th>
                  <th>
                    {{ $t("SendEmail") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(client, index) in getClientCompare"
                  :key="index"
                  class="hover-row"
                  style="border-bottom: 1px solid #dcdfe6; color: #606266; cursor: pointer"
                >
                  <td>
                    {{ client.firstName }}
                    {{ client.lastName }}
                  </td>
                  <td>{{ $t(client.gender) }}</td>
                  <td>{{ client.email }}</td>
                  <td>+{{ countryCallingCode[client.countryCode] }} {{ client.telephoneNumber }}</td>
                  <td>
                    <b-form-group
                      id="input-group-1"
                      style="width: 40%;"
                      label-for="input-1"
                    >
                      <b-form-input
                        id="input-1"
                        v-model.number="client.discount"
                        type="number"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t("This_is_a_required_field_and_must_be_at_least_3_letters")
                      }}</b-form-invalid-feedback>
                    </b-form-group>
                  </td>
                  <td>
                    <b-button
                      type="button"
                      variant="none"

                      class="buttonSubmit"
                      @click="onSendEmail(client)"
                    >
                      {{ $t("SendEmail") }}
                    </b-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            v-if="clickedButton && getClientCompare.length==0"
            style="display: flex; flex-direction: column; width: 100%"
          >
            <p>{{ $t('NoClients') }}</p>
          </div>
        </div>
        <pre />
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { client } from '@/domainConfig';
import { countryCallingCodes } from '@/countryCodes'

export default {
  components: {},
  // eslint-disable-next-line vue/require-prop-types
  props: ['vacationId'],
  data() {
    return {
      searchDress: '',
      noSearch: false,
      page: 1,
      pageSize: 15,
      arrowCounter: 0,
      enterPressed: false,
      articlename: null,
      aroundBust: null,
      waist: null,
      aroundHips: null,
      length1: null,
      clientName: client.clientName,
      clientLogo: client.clientLogo,
      countryCallingCode: countryCallingCodes,
      clickedButton: false,
    };
  },
  computed: {
    ...mapGetters([
      'getfreeTeamLeaders',
      'getVacationRequests',
      'getCurrentPageForOrders',
      'getFilteredDresses',
      'getTotalItemsForOrders',
      'getProductsInStock',
      'getimagesProductionManagement',
      'getClientCompare',
    ]),
  },
  watch: {
    searchDress(value) {
      if (value == '') {
        this.removeLists();
        this.loadDressesPagination({
          pageNumber: this.page,
          pageSize: this.pageSize,
          mdcName: this.searchDress,
        });
      } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
        return;
      } else {
        this.filteredDressesByName(value);
        this.noSearch = true;
      }
    },
    articlename(value) {
      this.resetFileByArticleNumber();
      this.getFileByArticleNumber(value.articleNumber);
    },
  },
  mounted() {},
  methods: {
    ...mapActions([
      'loadUsersFullNamesById',
      'resetFileByArticleNumber',
      'getFileByArticleNumber',
      'loadProductInStock',
      'cancelVacation',
      'filteredDressesByName',
      'resetFilteredDresses',
      'loadDressesPagination',
      'loadClientCompare',
      'resetCLientsCompare',
      'sendEmailToClientForDress',
    ]),
    onSubmit() {
      this.$emit('cancel', this.vacationId);
      this.$refs.modal.hide();
      this.onReset();
    },
    findClient() {
      this.loadClientCompare({
        aroundBust: this.aroundBust,
        waist: this.waist,
        aroundHips: this.aroundHips,
        length1: this.length1,
        clientName: this.clientName,
      });
      this.clickedButton = true
    },
    onSendEmail(value) {
      console.log(value)
      const fullName = `${value.firstName} ${value.lastName}`
      this.sendEmailToClientForDress({
        object: {
          email: value.email,
          discount: value.discount,
          fullName,
          ocassion: 'Offer',
          iconUrl: this.clientLogo,
          dressUrl: this.getimagesProductionManagement[0].url,
          // iconUrl: 'https://spim-file.s3.amazonaws.com/DrenushaLogoWhite.png',
        },
      })
      value.discount = 0
    },
    next() {
      if (this.page < this.getTotalItemsForOrders) {
        this.page += 1;
        // this.getMVbyMeter(this.pageM, this.pageSize);
        this.loadProductInStock({
          assignedTeamNameId: null,
          articleNumber: null,
          state: 'InStock',
          pageNumber: this.page,
          pageSize: 15,
        });
      }
    },
    prev() {
      if (this.page > 1) {
        this.page -= 1;
        // this.getMVbyMeter(this.pageM, this.pageSize);
        this.loadProductInStock({
          assignedTeamNameId: null,
          articleNumber: null,
          state: 'InStock',
          pageNumber: this.page,
          pageSize: 15,
        });
      }
    },
    onArrowDown(ev) {
      ev.preventDefault();
      if (this.arrowCounter < this.getFilteredDresses.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },

    onArrowUp(ev) {
      ev.preventDefault();
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling();
      }
    },
    async searchByOn(value) {
      console.log('value', value);
      this.searchDress = value;
      this.noSearch = false;
      await this.loadDressesPagination({
        pageNumber: this.page,
        pageSize: this.pageSize,
        mdcName: this.searchDress,
      });
    },
    removeLists() {
      this.noSearch = false;
      this.resetFilteredDresses();
    },

    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') {
        return;
      }
      // this.getFilteredDresses = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true;
        this.searchByOn(this.getFilteredDresses[this.arrowCounter]);
        this.removeLists();
        this.arrowCounter = 0;
        setTimeout(() => {
          this.enterPressed = false;
        }, 1000);
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH);
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },

    onCancel() {
      this.resetFileByArticleNumber();
      this.resetCLientsCompare()
      this.$refs.modal.hide();
      this.onReset();
    },
    onReset() {
      this.aroundBust = '';
      this.waist = '';
      this.aroundHips = '';
      this.length1 = '';
      this.resetFileByArticleNumber();
      this.resetCLientsCompare()
      this.articlename = '';
      this.page = 1;
      this.clickedButton = false;
    },
  },
};
</script>

<style lang="scss" scoped>
// form {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   gap: 10px;
// }
// .form {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   gap: 10px;
// }
.form2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons {
  display: flex;
  align-items: flex-end;
}

.hover-row:hover {
  background-color: #f5f5f5; /* Set your desired background color here */
}
.mats-listing__wrapper {
  .mats-listing {
    margin-top: 0;
  }
}
.button {
  margin-bottom: 15px !important;
  margin-top: 14px;
  width: 195px;
  background: transparent linear-gradient(52deg, #262e6c 0%, #9b2a5c 100%, #ff274f 100%) 0% 0%
    no-repeat padding-box;
  color: #f4f1ed;
  border-color: transparent;
}
.mat {
  display: flex;
  justify-content: space-between;
  align-content: center;
  &.active {
    background-color: #e8e8e8;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  span {
    margin-left: 10px;
    svg {
      font-size: 15px;
    }
  }
}
.justify-content-end {
  justify-content: flex-start !important;
}
// .team_table .tr{
//   border-radius: 10px 10px 10px 10px !important;
// }
tr:last-child {
  border-bottom: none !important;
}
@media screen and (max-width: 1823px) {
  .mats-listing__wrapper2 {
    width: 250px !important;
  }
  .tt {
    width: 250px;
  }
}
@media screen and (max-width: 1400px) {
  .mats-listing__wrapper {
    width: 150px !important;
    margin-top: 59px !important;
    margin-right: 5px;
    .tt {
      width: 200px;
    }
  }
  .mats-listing__wrapper2 {
    width: 200px !important;
    button {
      width: 150px;
      font-size: 13px;
      height: 36px;
    }
    .tt {
      width: 200px;
    }
  }
}

.dropdrop::-webkit-scrollbar {
  display: none;
}
.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: none;;
}

input[name="search-box"] {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}
.black {
  position: relative;
}
.black input {
  border: none;
  box-shadow: none;;
}
.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}
.search-item:last-child {
  border-bottom: none;
}
.search-item:hover {
  background: #82868c;
  color: white;
}
.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}
@media screen and (max-width: 1400px) {
  input[name="search-box"] {
    display: block;
    // max-width: 500px;
    min-width: 200px;
    padding: 0.5rem 1rem;
    padding-left: 25px;
  }
}
@media screen and (max-width: 896px) {
  .mats-listing__wrapper {
    width: auto !important;
    flex-basis: 49%;
  }
  .tt {
    margin-right: 0 !important;
    width: auto !important;
  }
}
.is-active {
  background-color: #dedede;
}
</style>
