<template>
  <div>
    <b-modal
      id="add-new-client"
      ref="modal"
      header-class="headerModal"
      centered
      size="xl"
      modal-class="dd"
      :title="$t('SubmitClient')"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <section
          class="custom-modal__wrapper"
          style="border-width: 3px"
        >
          <b-tabs
            v-model="tabIndex"
          >
            <b-tab
              :title="$t('PersonalInfo')"
              active
            >
              <template #title>
                <strong style="font-weight: 500; color: black">{{ $t("PersonalInfo") }}</strong>
              </template>
              <div>
                <b-collapse
                  id="collapse-345"
                  visible
                >
                  <div class="hello">
                    <b-form-group :label="$t('Salutation')">
                      <vue-select
                        v-model="clientInfo.salutation"
                        :options="salutation"
                        :placeholder="$t('SelectSalutation')"
                        aria-describedby="input-1-live-feedback"
                      />

                    </b-form-group>

                    <b-form-group :label="`${$t('FirstName')}`">
                      <b-form-input
                        id="firstName"
                        v-model="clientInfo.firstName"
                        :state="validateState('firstName')"
                        placeholder="e.g. Michael"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                      }}</b-form-invalid-feedback>
                    </b-form-group>
                  </div>

                  <div class="hello">
                    <b-form-group :label="`${$t('LastName')}`">
                      <b-form-input
                        id="lastname"
                        v-model="clientInfo.lastname"
                        :state="validateState('lastname')"
                        placeholder="e.g. Smith"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                      }}</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group
                      id="input-group-9"
                      :label="`${$t('DateofBirth')}:`"
                      label-for="input-9"
                      style="width:49%"
                    >
                      <date-picker
                        v-model="selectedDate"
                        value-type="format"
                        class="form-control"
                        format="DD-MM-YYYY"
                        type="date"
                        :placeholder="$t('SelectD')"
                        style="width: 100%;"
                        :lang="lang"
                      />
                    </b-form-group>

                  </div>
                  <div class="hello">
                    <b-form-group :label="`${$t('Gender')}`">
                      <vue-select
                        v-model="clientInfo.gender"
                        :options="genderOptions"
                        :reduce="(e) => e.value"
                        label="text"
                        :placeholder="$t('SelectGender')"
                        aria-describedby="input-1-live-feedback"
                      />

                    </b-form-group>
                    <b-form-group :label="`${$t('Email')}`">
                      <b-form-input
                        id="email"
                        v-model="clientInfo.email"
                        placeholder="e.g. example@digitbusiness.ch"
                        aria-describedby="input-1-live-feedback"
                      />

                    </b-form-group>

                  </div>
                  <div class="hello">
                    <b-form-group :label="`${$t('PhoneNumber')}`">
                      <vue-phone-number-input
                        id="phone"
                        v-model="clientInfo.telephoneNumber"
                        default-country-code="XK"
                        :preferred-countries="['AL', 'CH', 'DE']"
                        @update="getFormattedNumber"
                      />
                    </b-form-group>
                  </div>
                </b-collapse>
              </div>
            </b-tab>
            <b-tab

              :title="$t('BillingAddress')"
            >
              <template #title>
                <strong style="font-weight: 500; color: black">{{ $t("BillingAddress") }}</strong>
              </template>
              <div>
                <b-collapse
                  id="collapse-345"
                  visible
                >
                  <div class="hello">
                    <b-form-group :label="`${$t('Street')}`">
                      <b-form-input
                        id="street"
                        v-model="clientInfo.billingAddressInternalDto.street"
                        placeholder="e.g. Rexhep Mala"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                      }}</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group :label="`${$t('HouseNumber')}`">
                      <b-form-input
                        id="houseNr"
                        v-model="clientInfo.billingAddressInternalDto.houseNumber"
                        type="text"
                        step="any"
                        min="0"
                        placeholder="e.g. 30"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback"> {{
                        $t('This_is_a_required_field_and_must_contains_numbers')
                      }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <div class="hello">
                    <b-form-group :label="`${$t('DoorNumber')}`">
                      <b-form-input
                        id="doorNr"
                        v-model="clientInfo.billingAddressInternalDto.doorNumber"
                        type="text"
                        placeholder="e.g. 50"
                        step="any"
                        min="0"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_contains_numbers')
                      }}
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group :label="`${$t('PostalCode')}`">
                      <b-form-input
                        id="postal"
                        v-model="clientInfo.billingAddressInternalDto.postalCode"
                        min="0"
                        placeholder="e.g. 10000"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                      }}</b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <div class="hello">
                    <b-form-group :label="`${$t('City')}`">
                      <b-form-input
                        id="city"
                        v-model="clientInfo.billingAddressInternalDto.city"
                        placeholder="e.g. Prishtinë"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                      }}</b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group :label="`${$t('Country')}`">
                      <b-form-input
                        id="country"
                        v-model="clientInfo.billingAddressInternalDto.country"
                        placeholder="e.g. Kosovë"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                      }}</b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </b-collapse>
              </div>
            </b-tab>
            <b-tab

              :title="$t('ShippingAddress')"
            >
              <template #title>
                <strong style="font-weight: 500; color: black">{{ $t("ShippingAddress") }}</strong>
              </template>
              <div>
                <b-collapse
                  id="collapse-345"
                  visible
                >
                  <div>
                    <div class="hello">
                      <b-form-group :label="`${$t('Street')}`">
                        <b-form-input
                          v-model="clientInfo.shippingAddressInternalDto.street"
                          :disabled="pickUpInStore"
                          placeholder="e.g. Rexhep Maja"
                          aria-describedby="input-1-live-feedback"
                        />
                        <b-form-invalid-feedback>{{
                          $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                        }}</b-form-invalid-feedback>
                      </b-form-group>

                      <b-form-group :label="`${$t('HouseNumber')}`">
                        <b-form-input
                          v-model="clientInfo.shippingAddressInternalDto.houseNumber"
                          :disabled="pickUpInStore"
                          placeholder="e.g. 30"
                          type="text"
                          step="any"
                          min="0"
                          aria-describedby="input-1-live-feedback"
                        />
                        <b-form-invalid-feedback id="input-1-live-feedback">{{
                          $t('This_is_a_required_field_and_must_contains_numbers')
                        }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <div class="hello">
                      <b-form-group :label="`${$t('DoorNumber')}`">
                        <b-form-input
                          v-model="clientInfo.shippingAddressInternalDto.doorNumber"
                          :disabled="pickUpInStore"
                          type="text"
                          placeholder="e.g. 50"
                          step="any"
                          min="0"
                          aria-describedby="input-1-live-feedback"
                        />
                        <b-form-invalid-feedback id="input-1-live-feedback">{{
                          $t('This_is_a_required_field_and_must_contains_numbers')
                        }}
                        </b-form-invalid-feedback>
                      </b-form-group>

                      <b-form-group :label="`${$t('PostalCode')}`">
                        <b-form-input
                          v-model="clientInfo.shippingAddressInternalDto.postalCode"
                          :disabled="pickUpInStore"
                          placeholder="e.g. 10000"
                          min="0"
                          aria-describedby="input-1-live-feedback"
                        />
                        <b-form-invalid-feedback id="input-1-live-feedback">{{
                          $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <div class="hello">
                      <b-form-group :label="`${$t('City')}`">
                        <b-form-input
                          v-model="clientInfo.shippingAddressInternalDto.city"
                          :disabled="pickUpInStore"
                          placeholder="e.g. Prishtinë"
                          aria-describedby="input-1-live-feedback"
                        />
                        <b-form-invalid-feedback id="input-1-live-feedback">{{
                          $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                      <b-form-group :label="`${$t('Country')}`">
                        <b-form-input
                          v-model="clientInfo.shippingAddressInternalDto.country"
                          :disabled="pickUpInStore"
                          placeholder="e.g. Kosovë"
                          aria-describedby="input-1-live-feedback"
                        />
                        <b-form-invalid-feedback id="input-1-live-feedback">{{
                          $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <span
                      class="duplicate-material"
                      @click="duplicateBillingAddress(), resetPickUpInStore()"
                    >
                      {{ $t('SameAsBillingAddress') }}
                    </span>
                  </div>
                </b-collapse>
              </div>
            </b-tab>
            <b-tab
              :title="$t('Measurements')"
            >
              <template #title>
                <strong style="font-weight: 500; color: black">{{ $t("Measurements") }}</strong>
              </template>
              <div
                v-if="client == 'ValdrinSahiti'"
                class="skica-container"
              >
                <b-collapse
                  visible
                >
                  <div
                    class="front-body"
                  >

                    <div class="betweenCol">
                      <div class="input">
                        <span>{{ $t('toraks') }}</span>
                        <b-form-input
                          v-model="clientInfo.bodyMeasurementDto.thorax"
                          :placeholder="$t('')"
                          class="toraks1"
                        />
                      </div>
                      <div class="input">
                        <span>{{ $t('Bust') }}</span>
                        <b-form-input
                          v-model="clientInfo.bodyMeasurementDto.aroundBust"
                          :placeholder="$t('')"
                          class="rrethiGjoksit1"
                        />
                      </div>
                      <div class="input">
                        <span>{{ $t('UnderBust') }}</span>
                        <b-form-input
                          v-model="clientInfo.bodyMeasurementDto.aroundUnderBust"
                          :placeholder="$t('')"
                          class="rrethiNenGjoksit1"
                        />
                      </div>
                      <div class="input">
                        <span>{{ $t('Abdomen') }}</span>
                        <b-form-input
                          v-model="clientInfo.bodyMeasurementDto.waist"
                          :placeholder="$t('')"
                          class="beli1"
                        />
                      </div>
                      <div class="input">
                        <span>{{ $t('Hips') }}</span>
                        <b-form-input
                          v-model="clientInfo.bodyMeasurementDto.aroundHips"
                          :placeholder="$t('')"
                          class="vithet1"
                        />
                      </div>
                      <div class="input">
                        <span>{{ $t('BustReduction') }}</span>
                        <b-form-input
                          v-model="clientInfo.bodyMeasurementDto.aroundLeg"
                          :placeholder="$t('')"
                          class="rrethiKembes1"
                        />
                      </div>
                      <div class="input">
                        <span>{{ $t('UnderBustReduction') }}</span>
                        <b-form-input
                          v-model="clientInfo.bodyMeasurementDto.aroundKnee"
                          :placeholder="$t('')"
                          class="rrethiGjurit1"
                        />
                      </div>
                      <div class="input">
                        <span>{{ $t('FrontBody') }}</span>
                        <b-form-input
                          v-model="clientInfo.bodyMeasurementDto.ankle"
                          :placeholder="$t('')"
                          class="zoguKembeve1"
                        />
                      </div>


                    </div>
                    <div class="betweenCol">


                      <div class="input">
                        <span>{{ $t('BackBody') }}</span>
                        <b-form-input
                          v-model="clientInfo.bodyMeasurementDto.breastReduction"
                          :placeholder="$t('')"
                          class="reniaGjoksit1"
                        />
                      </div>
                      <div class="input">
                        <span>{{ $t('Shoulders') }}</span>
                        <b-form-input
                          v-model="clientInfo.bodyMeasurementDto.chestDistance"
                          :placeholder="$t('')"
                          class="distancaGjoksit1"
                        />
                      </div>
                      <div class="input">
                        <span>{{ $t('ShouldersSeam') }}</span>
                        <b-form-input
                          v-model="clientInfo.bodyMeasurementDto.sleeveLength"
                          :placeholder="$t('')"
                          class="distancaGjoksit1"
                        />
                      </div>
                      <div class="input">
                        <span>{{ $t('Distance') }}</span>
                        <b-form-input
                          v-model="clientInfo.bodyMeasurementDto.interBust"
                          :placeholder="$t('')"
                          class="nderGjoks1"
                        />
                      </div>

                      <div class="input">
                        <span>{{ $t('ButtocksReduction') }}</span>
                        <b-form-input
                          v-model="clientInfo.bodyMeasurementDto.frontBody"
                          :placeholder="$t('')"
                          class="trupiPara1"
                        />
                      </div>
                      <div class="input">
                        <span>{{ $t('Height') }}</span>
                        <b-form-input
                          v-model="clientInfo.bodyMeasurementDto.length1"
                          :placeholder="$t('')"
                          class="gjatesia11"
                        />
                      </div>
                      <div class="input">
                        <span>{{ $t('3QuartersLength') }}</span>
                        <b-form-input
                          v-model="clientInfo.bodyMeasurementDto.length2"
                          :placeholder="$t('')"
                          class="gjatesia21"
                        />
                      </div>
                    </div>
                    <div class="betweenCol">

                      <div class="input">
                        <span>{{ $t('ChanelLength') }}</span>
                        <b-form-input
                          v-model="clientInfo.bodyMeasurementDto.length3"
                          :placeholder="$t('')"
                          class="gjatesia31"
                        />
                      </div>
                      <div class="input">
                        <span>{{ $t('MiniLength') }}</span>
                        <b-form-input
                          v-model="clientInfo.bodyMeasurementDto.length4"
                          :placeholder="$t('')"
                          class="gjatesia41"
                        />
                      </div>
                      <div class="input">
                        <span>{{ $t('SleeveCircumference') }}</span>
                        <b-form-input
                          v-model="clientInfo.bodyMeasurementDto.aroundSleeve"
                          :placeholder="$t('')"
                          class="rrethiMenges1"
                        />
                      </div>
                      <div class="input">
                        <span>{{ $t('SleeveLength') }}</span>
                        <b-form-input
                          v-model="clientInfo.bodyMeasurementDto.elbow"
                          :placeholder="$t('')"
                          class="brryli1"
                        />
                      </div>
                      <div class="input">
                        <span>{{ $t('M.3QuartersLength') }}</span>
                        <b-form-input
                          v-model="clientInfo.bodyMeasurementDto.wrist"
                          :placeholder="$t('')"
                          class="kyçi1"
                        />
                      </div>
                      <div class="input">
                        <span>{{ $t('ElbowCircumference') }}</span>
                        <b-form-input
                          v-model="clientInfo.bodyMeasurementDto.aroundNeck"
                          :placeholder="$t('')"
                          class="rrethiQafes1"
                        />
                      </div>
                      <div class="input">
                        <span>{{ $t('WristCircumference') }}</span>
                        <b-form-input
                          v-model="clientInfo.bodyMeasurementDto.backBody"
                          :placeholder="$t('')"
                          class="trupiPrapa1"
                        />
                      </div>
                    </div>
                  </div>

                </b-collapse>
              </div>
              <div
                v-else
                class="skica-container"
                style="padding-top: 15px;"
              >
                <b-collapse
                  :id="`collapse`"
                  visible
                >
                  <div
                    class="btn-con"
                  >
                    <button
                      type="button"
                      @click="showBody = !showBody"
                    >
                      {{ $t('FlipBody') }}
                    </button>
                  </div>
                  <!-- v-if="dress.showFrontBody" -->
                  <div
                    v-if="showBody "
                    class="front-body"
                  >
                    <img
                      src="../assets/images/front-body.svg"
                      alt=""
                    >
                    <div class="toraks">
                      <b-form-input
                        v-model="clientInfo.bodyMeasurementDto.thorax"
                        :placeholder="$t('toraks')"
                        class="toraks1"
                      />
                    </div>
                    <div class="rrethiGjoksit">
                      <b-form-input
                        v-model="clientInfo.bodyMeasurementDto.aroundBust"
                        :placeholder="$t('rrethiGjoksit')"
                        class="rrethiGjoksit1"
                      />
                    </div>
                    <div class="rrethiNenGjoksit">
                      <b-form-input
                        v-model="clientInfo.bodyMeasurementDto.aroundUnderBust"
                        :placeholder="$t('rrethiNenGjoksit')"
                        class="rrethiNenGjoksit1"
                      />
                    </div>
                    <div class="beli">
                      <b-form-input
                        v-model="clientInfo.bodyMeasurementDto.waist"
                        :placeholder="$t('beli')"
                        class="beli1"
                      />
                    </div>
                    <div class="vithet">
                      <b-form-input
                        v-model="clientInfo.bodyMeasurementDto.aroundHips"
                        :placeholder="$t('RrethiVitheve')"
                        class="vithet1"
                      />
                    </div>
                    <div class="rrethiKembes">
                      <b-form-input
                        v-model="clientInfo.bodyMeasurementDto.aroundLeg"
                        :placeholder="$t('rrethiKembes')"
                        class="rrethiKembes1"
                      />
                    </div>
                    <div class="rrethiGjurit">
                      <b-form-input
                        v-model="clientInfo.bodyMeasurementDto.aroundKnee"
                        :placeholder="$t('rrethiGjurit')"
                        class="rrethiGjurit1"
                      />
                    </div>
                    <div class="zoguKembeve">
                      <b-form-input
                        v-model="clientInfo.bodyMeasurementDto.ankle"
                        :placeholder="$t('zoguKembeve')"
                        class="zoguKembeve1"
                      />
                    </div>

                    <div class="reniaGjoksit">
                      <b-form-input
                        v-model="clientInfo.bodyMeasurementDto.breastReduction"
                        :placeholder="$t('reniaGjoksit')"
                        class="reniaGjoksit1"
                      />
                    </div>
                    <div class="distancaGjoksit">
                      <b-form-input
                        v-model="clientInfo.bodyMeasurementDto.chestDistance"
                        :placeholder="$t('distancaGjoksit')"
                        class="distancaGjoksit1"
                      />
                    </div>
                    <div class="nderGjoks">
                      <b-form-input
                        v-model="clientInfo.bodyMeasurementDto.interBust"
                        :placeholder="$t('nderGjoks')"
                        class="nderGjoks1"
                      />
                    </div>
                    <div class="trupiPara">
                      <b-form-input
                        v-model="clientInfo.bodyMeasurementDto.frontBody"
                        :placeholder="$t('trupiPara')"
                        class="trupiPara1"
                      />
                    </div>
                    <div class="gjatesia1">
                      <b-form-input
                        v-model="clientInfo.bodyMeasurementDto.length1"
                        :placeholder="$t('gjatesia1')"
                        class="gjatesia11"
                      />
                    </div>
                    <div class="gjatesia2">
                      <b-form-input
                        v-model="clientInfo.bodyMeasurementDto.length2"
                        :placeholder="$t('gjatesia2')"
                        class="gjatesia21"
                      />
                    </div>
                    <div class="gjatesia3">
                      <b-form-input
                        v-model="clientInfo.bodyMeasurementDto.length3"
                        :placeholder="$t('gjatesia3')"
                        class="gjatesia31"
                      />
                    </div>
                    <div class="gjatesia4">
                      <b-form-input
                        v-model="clientInfo.bodyMeasurementDto.length4"
                        :placeholder="$t('gjatesia4')"
                        class="gjatesia41"
                      />
                    </div>
                  </div>
                  <!-- v-if="!dress.showFrontBody" -->
                  <div
                    v-if="!showBody"
                    class="back-body"
                  >
                    <img
                      src="../assets/images/back-body.svg"
                      alt=""
                    >
                    <div class="rrethiMenges">
                      <b-form-input
                        v-model="clientInfo.bodyMeasurementDto.aroundSleeve"
                        :placeholder="$t('rrethiMenges')"
                        class="rrethiMenges1"
                      />
                    </div>
                    <div class="brryli">
                      <b-form-input
                        v-model="clientInfo.bodyMeasurementDto.elbow"
                        :placeholder="$t('brryli')"
                        class="brryli1"
                      />
                    </div>
                    <div class="kyçi">
                      <b-form-input
                        v-model="clientInfo.bodyMeasurementDto.wrist"
                        :placeholder="$t('kyqi')"
                        class="kyçi1"
                      />
                    </div>
                    <div class="rrethiQafes">
                      <b-form-input
                        v-model="clientInfo.bodyMeasurementDto.aroundNeck"
                        :placeholder="$t('rrethiQafes')"
                        class="rrethiQafes1"
                      />
                    </div>
                    <div class="trupiPrapa">
                      <b-form-input
                        v-model="clientInfo.bodyMeasurementDto.backBody"
                        :placeholder="$t('trupiPrapa')"
                        class="trupiPrapa1"
                      />
                    </div>
                    <div class="supet">
                      <b-form-input
                        v-model="clientInfo.bodyMeasurementDto.shoulders"
                        :placeholder="$t('supet')"
                        class="supet1"
                      />
                    </div>
                    <div class="shpina">
                      <b-form-input
                        v-model="clientInfo.bodyMeasurementDto.back"
                        :placeholder="$t('shpina')"
                        class="shpina1"
                      />
                    </div>
                    <div class="reniaVitheve">
                      <b-form-input
                        v-model="clientInfo.bodyMeasurementDto.declineOfTheHips"
                        :placeholder="$t('reniaVitheve')"
                        class="reniaVitheve1"
                      />
                    </div>
                    <div class="gjatesiaMenges">
                      <b-form-input
                        v-model="clientInfo.bodyMeasurementDto.sleeveLength"
                        :placeholder="$t('gjatesiaMenges')"
                        class="gjatesiaMenges1"
                      />
                    </div>
                  </div>
                </b-collapse>
              </div>
            </b-tab>
          </b-tabs>
        </section>
      </b-form>
      <div class="buttonsEverywhere">
        <b-button
          v-if="tabIndex == 3"
          type="button"
          variant="none"
          style="margin-top: 20px;background: #FF274F !important;"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t('Submit') }}
        </b-button>
        <b-button
          v-else
          class="buttonSubmit"
          style="margin-top: 20px;background: #FF274F !important;"
          @click="tabIndex++"
        >
          {{ $t("Next") }}
        </b-button>
        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; margin-top: 20px;background: white; color: black; border-color: white; font-weight: 400;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>
    <!-- <div
          style="display: flex"
        >
          <h4
            class="headline"
          >
            {{ $t('CustomSize') }}
          </h4>
        </div>
        <div
          style="display: flex"
        >
          <h4
            class="headline"
          >
            {{ $t('StandardSize') }}
          </h4>
        </div> -->
    <!-- <b-collapse
          :id="`collapse`"
          visible
        >
          <p style="margin-left: 7px">
            v-model="dress.existingSizes"
            <b-form-checkbox
              id="checkbox-sizes"
              :name="`checkbox`"
              :value="true"
              :unchecked-value="false"
            >
              {{ $t('Regular Sizes') }}
            </b-form-checkbox>
          </p>
        </b-collapse> -->
    <!-- v-if="getEditOrder.bodyMeasurementDto !== null" -->

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';
import {
  required, minLength, alpha, alphaNum,
} from 'vuelidate/lib/validators';
// import moment from 'moment';
// import DatePicker from 'vue2-datepicker';
import { client } from '../domainConfig'
// import 'vue2-datepicker/index.css';


export default {
  components: {
    DatePicker,
  },
  mixins: [validationMixin],

  // eslint-disable-next-line vue/require-prop-types
  props: ['', 'addedArticle'],
  data() {
    return {
      client: client.clientName,
      tabIndex: 0,
      isPhoneNumberValid: null,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
      selectedDate: null,
      formSubmitted: false,
      config: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
      },
      orderTypeOptions: [
        { value: null, text: 'Choose a Type' },
        { value: 'InStore', text: 'InStore' },
        { value: 'Online', text: 'Online' },
        { value: 'WholeSale', text: 'WholeSale' },
      ],
      genderOptions: [
        { value: 'Male', text: 'Male' },
        { value: 'Female', text: 'Female' },
        { value: 'Other', text: 'Other' },
      ],
      selectedOrderType: null,
      pickUpInStore: false,
      partOptions: [],
      meterOptions: [],
      salutation: ['Mr.', 'Ms.', 'BA.', 'BSc.', 'DI.', 'MA.', 'MSc.', 'MBA.', 'PhD.'],
      clientInfo: {
        salutation: '',
        firstName: '',
        lastname: '',
        dateOfBirth: '',
        email: '',
        gender: '',
        telephoneNumber: '',
        countryCode: '',
        bodyMeasurementDto: {
          thorax: '',
          aroundBust: '',
          aroundUnderBust: '',
          waist: '',
          aroundHips: '',
          aroundLeg: '',
          aroundKnee: '',
          ankle: '',
          breastReduction: '',
          interBust: '',
          frontBody: '',
          chestDistance: '',
          length1: '',
          length2: '',
          length3: '',
          length4: '',
          aroundSleeve: '',
          elbow: '',
          wrist: '',
          aroundNeck: '',
          backBody: '',
          shoulders: '',
          back: '',
          declineOfTheHips: '',
          sleeveLength: '',
        },
        billingAddressInternalDto: {
          street: '',
          houseNumber: '',
          doorNumber: '',
          postalCode: '',
          city: '',
          country: '',
        },
        shippingAddressInternalDto: {
          street: '',
          houseNumber: '',
          doorNumber: '',
          postalCode: '',
          city: '',
          country: '',
        },
      },
      showBody: true,
      isSubmitClicked: false,
      form: [],
      forms: [],
      customDresses: [],
      error: {
        oType: '',
        duplicateError: '',
      },
      formItem: [],
      sizeOptions: [
        {
          size: 'xs',
        },
        {
          size: 's',
        },
        {
          size: 'm',
        },
        {
          size: 'l',
        },
        {
          size: 'xl',
        },
      ],
    };
  },

  validations: {
    selectedOrderType: {
      required,
    },
    clientInfo: {
      salutation: {
      },
      firstName: {
        required,
        minLength: minLength(3),
        alpha,
      },
      lastname: {
        required,
        minLength: minLength(3),
        alpha,
      },
      email: {
      },
      gender: {
      },
      billingAddressInternalDto: {
        street: {
          required,
          minLength: minLength(3),
        },
        houseNumber: {
        },
        doorNumber: {
        },
        postalCode: {
          required,
          minLength: minLength(3),
        },
        city: {
          required,
        },
        country: {
          required,
        },
      },
      shippingAddressInternalDto: {
        street: {
          required,
          minLength: minLength(3),
        },
        houseNumber: {
        },
        doorNumber: {
        },
        postalCode: {
          required,
          alphaNum,
          minLength: minLength(3),
        },
        city: {
          required,
        },
        country: {
          required,
        },
      },
    },
  },
  computed: {
    ...mapGetters(['getEditOrder']),
  },
  watch: {
    // 'getEditOrder.bodyMeasurementDto': function (value) {
    //   if (value == null) {
    //     this.clientInfo.bodyMeasurementDto = null
    //   }
    // },
    // getEditOrder: {
    //   handler(newValue) {
    //     this.clientInfo.bodyMeasurementDto = newValue.bodyMeasurementDto;
    //   },
    // },
    selectedDate(value) {
      const time = moment(value, 'DD-MM-YYYY').format('YYYY-MM-DD')
      this.clientInfo.dateOfBirth = time
    },
    form: {
      deep: true,
    },
    pickUpInStore(value) {
      if (value == true) {
        this.clientInfo.shippingAddressInternalDto.street = '';
        this.clientInfo.shippingAddressInternalDto.houseNumber = '';
        this.clientInfo.shippingAddressInternalDto.doorNumber = '';
        this.clientInfo.shippingAddressInternalDto.postalCode = '';
        this.clientInfo.shippingAddressInternalDto.city = '';
        this.clientInfo.shippingAddressInternalDto.country = '';
      }
    },
    selectedOrderType(value) {
      if (value !== null) {
        this.error.oType = ''
      }
    },
    measureItem() {
      this.form.measurementType = this.measureItem;
    },
  },
  mounted() {
    // this.clientInfo.bodyMeasurementDto = this.getEditOrder.bodyMeasurementDto;
  },
  methods: {
    ...mapActions([
      'resetCustomOrders',
    ]),
    getFormattedNumber(n) {
      if (n.isValid == true) {
        this.isPhoneNumberValid = true
        this.clientInfo.telephoneNumber = n.nationalNumber
        this.clientInfo.countryCode = n.countryCode
      } else {
        this.clientInfo.countryCode = ''
        this.isPhoneNumberValid = true
        return;
      }
      console.log()
    },
    fillForms() {
      this.forms = []
      for (let i = 0; i < this.addedArticle.length; i++) {
        this.forms.push(this.addedArticle[i].materials.productSingleMaterialVariantDtos)
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.clientInfo[name];
      return $dirty ? !$error : null;
    },
    // validateState2(name) {
    //   const { $dirty, $error } = this.$v.clientInfo.billingAddressInternalDto[name];
    //   return $dirty ? !$error : null;
    // },
    // validateState3(name) {
    //   const { $dirty, $error } = this.$v.clientInfo.shippingAddressInternalDto[name];
    //   return $dirty ? !$error : null;
    // },
    validateState4(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },

    removeCustomDress(idx) {
      this.customDresses.splice(idx, 1)
      this.$emit('decrementCounter')
    },
    resetPickUpInStore() {
      this.pickUpInStore = false
    },
    duplicateBillingAddress() {
      this.clientInfo.shippingAddressInternalDto.street = this.clientInfo.billingAddressInternalDto.street;
      this.clientInfo.shippingAddressInternalDto.houseNumber = this.clientInfo.billingAddressInternalDto.houseNumber;
      this.clientInfo.shippingAddressInternalDto.doorNumber = this.clientInfo.billingAddressInternalDto.doorNumber;
      this.clientInfo.shippingAddressInternalDto.postalCode = this.clientInfo.billingAddressInternalDto.postalCode;
      this.clientInfo.shippingAddressInternalDto.city = this.clientInfo.billingAddressInternalDto.city;
      this.clientInfo.shippingAddressInternalDto.country = this.clientInfo.billingAddressInternalDto.country;
    },
    addNewRow(index) {
      if (this.forms[index].length > 0) {
        this.forms[index].push({
          materialVariantId: '',
          measurementType: 'Meter',
          name: '',
          length: 0,
          quantity: 0,
        });
      }
    },
    addNewCustomDress() {
      this.customDresses.push({
        title: `Custom Dress ${this.customDresses.length + 1}`,
        formItem: [],
        pickUpDate: '',
        filledPickUpDate: true,
        standardSize: '',
        eArticle: false,
        existingSizes: false,
        showFrontBody: true,
        sizesDto: {
          thorax: '',
          aroundBust: '',
          aroundUnderBust: '',
          waist: '',
          aroundHips: '',
          aroundLeg: '',
          aroundKnee: '',
          ankle: '',
          breastReduction: '',
          interBust: '',
          frontBody: '',
          chestDistance: '',
          length1: '',
          length2: '',
          length3: '',
          length4: '',
          aroundSleeve: '',
          elbow: '',
          wrist: '',
          aroundNeck: '',
          backBody: '',
          shoulders: '',
          back: '',
          declineOfTheHips: '',
          sleeveLength: '',
        },
        duplicateMV: false,
      })
      this.$emit('incrementCounter')
    },

    onSubmit() {
      this.formSubmitted = true;
      this.isSubmitClicked = true
      // this.$v.clientInfo.billingAddressInternalDto.$touch();
      this.$v.clientInfo.$touch();
      // if (this.pickUpInStore == false) {
      //   this.$v.clientInfo.shippingAddressInternalDto.$touch();
      // }
      // if (this.pickUpInStore == false) {
      //   if (
      //     this.$v.clientInfo.billingAddressInternalDto.$anyError
      //   // || this.$v.clientInfo.$anyError
      //   || this.$v.clientInfo.shippingAddressInternalDto.$anyError
      //   // || this.isPhoneNumberValid == false
      //   // || this.isPhoneNumberValid == null
      //   ) {
      //     if (this.isPhoneNumberValid == null) {
      //       this.isPhoneNumberValid = false
      //     }
      //     return;
      //   }
      // }
      if (this.pickUpInStore == true) {
        if (this.$v.clientInfo.$anyError
        // || this.isPhoneNumberValid == false
        // || this.isPhoneNumberValid == null
        ) {
        //   if (this.isPhoneNumberValid == null) {
        //     this.isPhoneNumberValid = false
        //   }
        //   return;
        }
      }
      const formatDate = moment(this.clientInfo.dateOfBirth, 'DD-MM-YYYY').format('YYYY-MM-DD')
      this.$emit('click', { ...this.clientInfo, dateOfBirth: formatDate });
      this.$refs.modal.hide();
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      setTimeout(() => {
        this.resetForm();
      }, 10);
      console.log('sda')
    },
    onCancel() {
      this.$refs.modal.hide();
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      setTimeout(() => {
        this.resetForm();
        this.materials = [];
      }, 10);
    },
    resetShippingAddress() {
      // if (this.pickUpInStore == true) {
      // this.$v.clientInfo.shippingAddressInternalDto.$anyError = true;
      //   this.error.oType = ''
      this.$v.clientInfo.shippingAddressInternalDto.$reset()
      // }
      // this.$refs.modal.hide();
    },
    resetForm() {
      // Reset our form values
      this.customDresses = []
      this.forms = []
      this.error.oType = ''
      this.resetCustomerData();
      this.resetCustomOrders();
      this.isSubmitClicked = false;
      this.formSubmitted = false
    },
    resetCustomerData() {
      this.clientInfo.salutation = '';
      this.clientInfo.firstName = '';
      this.clientInfo.lastname = '';
      this.clientInfo.email = '';
      this.clientInfo.gender = '';
      this.clientInfo.telephoneNumber = '';
      this.clientInfo.dateOfBirth = '';


      this.clientInfo.billingAddressInternalDto.street = '';
      this.clientInfo.billingAddressInternalDto.houseNumber = '';
      this.clientInfo.billingAddressInternalDto.doorNumber = '';
      this.clientInfo.billingAddressInternalDto.postalCode = '';
      this.clientInfo.billingAddressInternalDto.city = '';
      this.clientInfo.billingAddressInternalDto.country = '';

      this.clientInfo.shippingAddressInternalDto.street = '';
      this.clientInfo.shippingAddressInternalDto.houseNumber = '';
      this.clientInfo.shippingAddressInternalDto.doorNumber = '';
      this.clientInfo.shippingAddressInternalDto.postalCode = '';
      this.clientInfo.shippingAddressInternalDto.city = '';
      this.clientInfo.shippingAddressInternalDto.country = '';

      this.bodyMeasurementDto.thorax = '';
      this.bodyMeasurementDto.aroundBust = '';
      this.bodyMeasurementDto.aroundUnderBust = '';
      this.bodyMeasurementDto.waist = '';
      this.bodyMeasurementDto.aroundHips = '';
      this.bodyMeasurementDto.aroundLeg = '';
      this.bodyMeasurementDto.aroundKnee = '';
      this.bodyMeasurementDto.ankle = '';
      this.bodyMeasurementDto.breastReduction = '';
      this.bodyMeasurementDto.interBust = '';
      this.bodyMeasurementDto.frontBody = '';
      this.bodyMeasurementDto.chestDistance = '';
      this.bodyMeasurementDto.length1 = '';
      this.bodyMeasurementDto.length2 = '';
      this.bodyMeasurementDto.length3 = '';
      this.bodyMeasurementDto.length4 = '';
      this.bodyMeasurementDto.aroundSleeve = '';
      this.bodyMeasurementDto.elbow = '';
      this.bodyMeasurementDto.wrist = '';
      this.bodyMeasurementDto.aroundNeck = '';
      this.bodyMeasurementDto.backBody = '';
      this.bodyMeasurementDto.shoulders = '';
      this.bodyMeasurementDto.back = '';
      this.bodyMeasurementDto.declineOfTheHips = '';
      this.bodyMeasurementDto.sleeveLength = '';

      this.pickUpInStore = false;
      this.isPhoneNumberValid = null
    },

  },
};
</script>

<style lang="scss" scoped>

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
    width: 49%;
}

.hello{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}


form {
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}


.headline {
  padding: 10px 10px;
  background-color: white;
  color: black;
  font-weight: bold;
  border-radius: 8px;
}

.custom-modal__wrapper {
  display: flex;
  justify-content: space-between;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px 0px;

  >div {
    flex: 1;
    margin: 0px 15px;
  }
}

.flex-form {
  display: flex;
  flex-direction: column;
}

.fixed-height-modal {
  height: 100%;
  overflow-y: auto;
  padding-right: 1rem;
}

.customDress-container {
  border: 3px solid lightgray;
  border-radius: 10px;
  padding: 15px;
  margin-top: 20px;
}

.customDress-container:last-child {
  margin-bottom: 20px;
}

.inside-actions {
  margin-left: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  box-shadow: none;
  padding: 2px 4px;
  border-radius: 7px;
}

.inside-actions:hover {
  background-color: #242F6E;
  color: #fff;
}

.f {
  border-color: red !important;
}

.front-body {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 50px;
  padding-bottom: 20px;

  img {
    width: 100%;
  }
  .betweenCol{
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .input{
    max-width: 100%;
    height: 51px;
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
  .toraks {
    display: flex;
    position: absolute;
    top: 106px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .rrethiGjoksit {
    display: flex;
    position: absolute;
    top: 135px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .rrethiNenGjoksit {
    display: flex;
    position: absolute;
    top: 165px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .beli {
    display: flex;
    position: absolute;
    top: 200px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .vithet {
    display: flex;
    position: absolute;
    top: 263px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .rrethiKembes {
    display: flex;
    position: absolute;
    bottom: 222px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .rrethiGjurit {
    display: flex;
    position: absolute;
    bottom: 148px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .zoguKembeve {
    display: flex;
    position: absolute;
    bottom: 38px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .reniaGjoksit {
    display: flex;
    position: absolute;
    top: 85px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .nderGjoks {
    display: flex;
    position: absolute;
    top: 158px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .trupiPara {
    display: flex;
    position: absolute;
    top: 200px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .distancaGjoksit {
    display: flex;
    position: absolute;
    top: 128px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .gjatesia1 {
    display: flex;
    position: absolute;
    bottom: 222px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .gjatesia2 {
    display: flex;
    position: absolute;
    bottom: 127px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .gjatesia3 {
    display: flex;
    position: absolute;
    bottom: 68px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .gjatesia4 {
    display: flex;
    position: absolute;
    bottom: 8px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
}

.btn-con {
  display: flex;

  button {
    border: none;
    background: #FF274F;
    color: $digit-white;
    border-radius: 0.25rem;
    padding: 3px 12px;
  }
}

.back-body {
  position: relative;

  img {
    width: 100%;
  }

  .rrethiMenges {
    display: flex;
    position: absolute;
    top: 126px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .brryli {
    display: flex;
    position: absolute;
    top: 156px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .kyçi {
    display: flex;
    position: absolute;
    top: 192px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .rrethiQafes {
    display: flex;
    position: absolute;
    top: 60px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .trupiPrapa {
    display: flex;
    position: absolute;
    top: 202px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .supet {
    display: flex;
    position: absolute;
    top: 97px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .shpina {
    display: flex;
    position: absolute;
    top: 135px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .reniaVitheve {
    display: flex;
    position: absolute;
    bottom: 253px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .gjatesiaMenges {
    display: flex;
    position: absolute;
    top: 95px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
}

@media only screen and (max-width: 1200px) {
  .front-body {
    position: relative;

    .toraks {
      top: 65px;
      left: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .rrethiGjoksit {
      position: absolute;
      top: 95px;
      left: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .rrethiNenGjoksit {
      position: absolute;
      top: 125px;
      left: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .beli {
      position: absolute;
      top: 155px;
      left: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .vithet {
      position: absolute;
      top: 249px;
      right: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .rrethiKembes {
      position: absolute;
      bottom: 65px;
      right: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .rrethiGjurit {
      position: absolute;
      bottom: 35px;
      right: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .zoguKembeve {
      position: absolute;
      bottom: 5px;
      right: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .reniaGjoksit {
      position: absolute;
      top: 65px;
      right: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .nderGjoks {
      position: absolute;
      top: 125px;
      right: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .trupiPara {
      position: absolute;
      top: 155px;
      right: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .distancaGjoksit {
      position: absolute;
      top: 95px;
      right: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .gjatesia1 {
      position: absolute;
      bottom: 95px;
      left: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .gjatesia2 {
      position: absolute;
      bottom: 65px;
      left: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .gjatesia3 {
      position: absolute;
      bottom: 35px;
      left: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .gjatesia4 {
      position: absolute;
      bottom: 5px;
      left: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }
  }

  .btn-con {
    display: flex;

    button {
      border: none;
      background-color: #6c757d;
      ;
      color: $digit-white;
      border-radius: 0.25rem;
      padding: 3px 12px;
    }
  }

  .back-body {
    position: relative;

    img {
      width: 100%;
    }

    .rrethiMenges {
      position: absolute;
      top: 126px;
      right: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .brryli {
      position: absolute;
      top: 156px;
      right: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .kyçi {
      position: absolute;
      top: 192px;
      left: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .rrethiQafes {
      position: absolute;
      top: 70px;
      right: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .trupiPrapa {
      position: absolute;
      top: 215px;
      right: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .supet {
      position: absolute;
      top: 97px;
      left: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .shpina {
      position: absolute;
      top: 70px;
      left: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .reniaVitheve {
      position: absolute;
      bottom: 100px;
      right: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .gjatesiaMenges {
      position: absolute;
      top: 98px;
      right: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }
  }
}

input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -3px;
  left: -1px;
  position: relative;
  background-color: $base-color ;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

@media only screen and (max-width: 800px) {
  .front-body {
    .vithet {
      position: absolute;
      top: 195px;
      right: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }
  }
}
</style>
