<template>
  <div>
    <b-modal
      id="add-campaign"
      ref="modal"
      header-class="headerModal"
      no-close-on-backdrop
      centered
      title="Invite leads to campaign"
      hide-footer
    >
      <b-form-group>
        <div
          v-if="isAllChecked == true"
        >
          You have invited all of the leads
        </div>
        <div v-else>
          {{ clients.length }} leads have been invited to the campaign
        </div>
      </b-form-group>
      <b-form-group
        :label="$t('Select Campaign')"
      >
        <b-form-select
          :options="campaignTypes"
          style="
          padding: 11.5px;
          color: #82868c;
          border-radius: 8px;
          border: none;
          width: 100%;
          border: 1px solid lightgray;
          "
        />
      </b-form-group>
      <div class="buttonsEverywhere">
        <b-button
          type="button"
          variant="none"
          style="margin-left: 0px; background: #FF274F !important;"
          class="buttonSubmit"
          @click="onSubmit()"
        >
          {{ $t('Confirm') }}
        </b-button>
        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; background: white; color: black; border-color: white;font-weight: 400;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal></div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['clients', 'isAllChecked'],
  data() {
    return {
      modalTriggeredByToggle: false,
      campaignTypes: ['Birthday Campaign', 'Wedding Campaign'],
    }
  },
  watch: {
  },
  methods: {
    ...mapActions(['loadSaturdayWork']),
    onSubmit() {
      // const orderIds = this.selectedRows.map((item) => { return item.id });
      // this.$emit('orderArray', orderIds);
      this.$refs.modal.hide()
    },
    onCancel() {
      // this.$emit('saturdayy', this.saturday)

      this.modalTriggeredByToggle = false;
      this.$refs.modal.hide();
    },
  },
}
</script>

  <style lang="scss" scoped>
    form {
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    }
    .deactivate-modal__select {
      display: flex;
      flex-direction: column;
      select {
        margin-bottom: 10px;
        padding: 10px;
      }
      button {
        align-self: end;
      }
    }
    .flex-form {
      display: flex;
      flex-direction: column;
    }
    .my-class {
    background: black;
    font-weight: bold;
  }

  </style>
