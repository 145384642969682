<template>
  <div >
    <!-- <Navbar />
      <Sidebar /> -->
    <section class="main no-flex">
      <ClientTable />
    </section>
  </div>
</template>

<script>
// import HistoryTable from "../components/HistoryTable.vue";
import ClientTable from '@/components/clients/ClientsTable.vue';

export default {
  name: 'Client',
  components: {
    ClientTable,
  },
};
</script>
<style scoped lang="scss">

.main{
    padding: 75px 15px 15px 120px;
  }
@media screen and (max-width: 1700px) {
  .main{
    padding: 80px 15px 15px 90px;
  }
}
@media screen and (max-width: 1190px) {
  .main {
    padding: 90px 0.75rem 15px 0.75rem;
  }
}
</style>
