<template>
  <div>
    <b-modal
      id="add-event-leads"
      ref="modal"
      header-class="headerModal"
      no-close-on-backdrop
      centered
      :title="$t('Addevent')"
      hide-footer
      @close="onCancel"
    >
      <div style="display: grid; grid-template-columns: 2fr 1fr;">
        <b-form-group
          id="input-group-4"
          :label="$t('Type')"
          label-for="input-9"
        >
          <vue-select
            v-model="type"
            :options="types"
            :placeholder="$t('Select')"
            style="width: 224px;"
          />
        </b-form-group>
        <b-form-group
          v-if="type === 'Other'"
          id="input-group-other"
          :label="$t('Other Type')"
          label-for="input-other"
        >
          <b-form-input
            id="input-other"
            v-model="otherType"
            :placeholder="$t('Enter other type')"
            style="width: 224px;"
          />
        </b-form-group>
        <b-form-group
          id="input-group-1"
          :label="$t('Ocassion')"
          label-for="input-1"
        >
          <vue-select
            v-model="ocassion"
            :options="ocassionTypes"
            :placeholder="$t('Select')"
            style="width: 224px;"
          />
        </b-form-group>
      </div>
      <div>
        <b-form-group
          id="input-group-4"
          :label="`${$t('Notes')}:`"
          label-for="input-4"
        >
          <b-form-textarea
            id="input-4"
            v-model="notes"
            :placeholder="$t('Notes')"
            aria-describedby="input-1-live-feedback"
          />
        </b-form-group>
      </div>
      <div class="buttonsEverywhere">
        <button
          type="button"
          variant="none"
          style="margin-left: 0px;"
          class="buttonSubmit"
          @click="onSubmit()"
        >
          {{ $t('Confirm') }}
        </button>
        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; background: white; color: black; border-color: white;font-weight: 400;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal></div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  components: {
  },
  props: ['clientId', 'leadClient'],
  data() {
    return {
      type: null,
      otherType: null,
      notes: null,
      ocassion: null,
      ocassionTypes: [this.$t('Meeting'), this.$t('Birthday'), 'New Years Eve', 'Christmas', 'Eid Mubarak'],
      types: [this.$t('Phone'), this.$t('Email'), this.$t('SocialMedia'), this.$t('Other')],
    }
  },
  methods: {
    ...mapActions(['loadSaturdayWork']),
    onSubmit() {
      const objekti = {
        eventJsonRequestDto: {
          type: this.type,
          ocassion: this.ocassion,
          notes: this.notes,
        },
        clientId: this.clientId,
        salesUserId: this.leadClient,
      }
      this.$emit('events', objekti);
      this.$refs.modal.hide()
      this.onReset()
    },
    onCancel() {
      this.$refs.modal.hide();
      this.onReset()
    },
    onReset() {
      this.type = null
      this.notes = null
      this.ocassion = null
    },
    onTypeChange() {
      // Reset otherType when the type changes
      this.otherType = null;
    },
  },
}
</script>

  <style lang="scss" scoped>
    form {
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    }
    .deactivate-modal__select {
      display: flex;
      flex-direction: column;
      select {
        margin-bottom: 10px;
        padding: 10px;
      }
      button {
        align-self: end;
      }
    }
    .flex-form {
      display: flex;
      flex-direction: column;
    }
    .my-class {
    background: black;
    font-weight: bold;
  }

  </style>
